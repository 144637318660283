import { ColorScheme } from '@8f4e/sprite-generator';

const colorSchemes: Record<string, ColorScheme> = {
	default: {
		text: {
			lineNumber: 'rgba(51,51,51,255)',
			instruction: 'rgba(136,126,203,255)',
			codeComment: 'rgba(102,102,102,255)',
			code: 'rgba(255,255,255,255)',
			numbers: 'rgba(201,212,135,255)',
			menuItemText: 'rgba(255,255,255,255)',
			menuItemTextHighlighted: 'rgba(0,0,0,255)',
			dialogText: '#ffffff',
			dialogTitle: '#ffffff',
			binaryZero: 'rgba(201,212,135,255)',
			binaryOne: 'rgba(201,212,135,255)',
		},
		fill: {
			menuItemBackground: 'rgba(0,0,0,255)',
			menuItemBackgroundHighlighted: 'rgba(255,255,255,255)',
			background: '#000000',
			backgroundDots: '#444444',
			backgroundDots2: '#444444',
			moduleBackground: '#000000',
			moduleBackgroundDragged: 'rgba(0,0,0,0.8)',
			wire: '#ffffff',
			wireHighlighted: '#ffffff',
			errorMessageBackground: '#cc0000',
			dialogBackground: '#000000',
			dialogDimmer: 'rgba(0,0,0,0.5)',
			highlightedCodeLine: '#333333',
			plotterBackground: '#001100',
			plotterTrace: '#66ff66',
		},
		icons: {
			outputConnectorBackground: '#003300',
			inputConnectorBackground: '#003300',
			switchBackground: '#003300',
			inputConnector: '#ffffff',
			outputConnector: '#ffffff',
			feedbackScale: ['#ff0000', '#cc0033', '#990066', '#660099', '#3300cc', '#0000ff'],
			arrow: '#ffffff',
			pianoKeyWhite: '#ffffff',
			pianoKeyWhiteHighlighted: '#ff0000',
			pianoKeyWhitePressed: '#cccccc',
			pianoKeyBlack: '#000000',
			pianoKeyBlackHighlighted: '#ff0000',
			pianoKeyBlackPressed: '#333333',
			pianoKeyboardBackground: '#999999',
			pianoKeyboardNote: '#ffffff',
			pianoKeyboardNoteHighlighted: '#ff0000',
		},
	},
	hackerman: {
		text: {
			lineNumber: '#006600',
			instruction: '#99ff99',
			codeComment: '#999900',
			code: '#00cc00',
			numbers: '#00ff00',
			menuItemText: '#00ff00',
			menuItemTextHighlighted: '#000000',
			dialogText: '#ffffff',
			dialogTitle: '#ffffff',
			binaryZero: '#006600',
			binaryOne: '#00ff00',
		},
		fill: {
			menuItemBackground: 'rgba(0,0,0,0.9)',
			menuItemBackgroundHighlighted: '#99ff99',
			background: '#000000',
			backgroundDots: '#004400',
			backgroundDots2: '#006600',
			moduleBackground: 'rgba(0,0,0,0.9)',
			moduleBackgroundDragged: 'rgba(0,0,0,0.8)',
			wire: 'rgba(153,255,153,0.6)',
			wireHighlighted: 'rgba(204,255,204,0.8)',
			errorMessageBackground: '#cc0000',
			dialogBackground: '#000000',
			dialogDimmer: 'rgba(0,0,0,0.5)',
			highlightedCodeLine: '#002200',
			plotterBackground: '#001100',
			plotterTrace: '#99ff99',
		},
		icons: {
			outputConnectorBackground: '#002200',
			inputConnectorBackground: '#002200',
			switchBackground: '#002200',
			inputConnector: '#cccc00',
			outputConnector: '#cccc00',
			feedbackScale: ['#ff0000', '#cc0033', '#990066', '#660099', '#3300cc', '#0000ff'],
			arrow: '#00cc00',
			pianoKeyWhite: '#ffffff',
			pianoKeyWhiteHighlighted: '#ff0000',
			pianoKeyWhitePressed: '#ffffff',
			pianoKeyBlack: '#000000',
			pianoKeyBlackHighlighted: '#ff0000',
			pianoKeyBlackPressed: '#000000',
			pianoKeyboardBackground: '#006600',
			pianoKeyboardNote: '#ffffff',
			pianoKeyboardNoteHighlighted: '#ff0000',
		},
	},
	redalert: {
		text: {
			lineNumber: '#994433',
			instruction: '#eeaa66',
			codeComment: '#eecccc',
			code: '#cc9988',
			numbers: '#cc9988',
			menuItemText: '#ff0000',
			menuItemTextHighlighted: '#000000',
			dialogText: '#ffffff',
			dialogTitle: '#ffffff',
			binaryZero: '#ff0000',
			binaryOne: '#ff0000',
		},
		fill: {
			menuItemBackground: '#000000',
			menuItemBackgroundHighlighted: '#ff9999',
			background: '#220000',
			backgroundDots: '#660000',
			backgroundDots2: '#660000',
			moduleBackground: '#220000',
			moduleBackgroundDragged: 'rgba(34,0,0,0.8)',
			wire: 'rgba(255,153,153,0.6)',
			wireHighlighted: '#ffcccc',
			errorMessageBackground: '#cc0000',
			dialogBackground: '#000000',
			dialogDimmer: 'rgba(0,0,0,0.5)',
			highlightedCodeLine: '#660000',
			plotterBackground: '#001100',
			plotterTrace: '#00ff00',
		},
		icons: {
			outputConnectorBackground: '#220000',
			inputConnectorBackground: '#220000',
			switchBackground: '#220000',
			inputConnector: '#4499ff',
			outputConnector: '#4499ff',
			feedbackScale: ['#ff0000', '#cc0033', '#990066', '#660099', '#3300cc', '#0000ff'],
			arrow: 'cc0000',
			pianoKeyWhite: '#ffffff',
			pianoKeyWhiteHighlighted: '#ff0000',
			pianoKeyWhitePressed: '#cccccc',
			pianoKeyBlack: '#000000',
			pianoKeyBlackHighlighted: '#ff0000',
			pianoKeyBlackPressed: '#333333',
			pianoKeyboardBackground: '#999999',
			pianoKeyboardNote: '#ffffff',
			pianoKeyboardNoteHighlighted: '#ff0000',
		},
	},
};

export default colorSchemes;
