"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateLookup = void 0;
var font_1 = require("./font");
var types_1 = require("./fonts/types");
var types_2 = require("./types");
var offsetX = 0;
var offsetY = 300;
function generate(glyphs, characterWidth, characterHeight, colors) {
    var commands = [
        [types_2.Command.RESET_TRANSFORM],
        [types_2.Command.TRANSLATE, offsetX, offsetY],
        [types_2.Command.FILL_COLOR, colors.background],
        [types_2.Command.RECTANGLE, 0, 0, characterWidth * 64, characterHeight * 32],
    ];
    for (var i = 0; i < 32; i++) {
        for (var j = 0; j < 64; j++) {
            commands.push.apply(commands, __spreadArray(__spreadArray([j % 2 === 0 && i % 1 === 0
                    ? [types_2.Command.FILL_COLOR, colors.backgroundDots2]
                    : [types_2.Command.FILL_COLOR, colors.backgroundDots]], __read((0, font_1.drawCharacter)(glyphs, types_1.Glyph.DOT, characterWidth, characterHeight)), false), [[types_2.Command.TRANSLATE, characterWidth, 0]], false));
        }
        commands.push([types_2.Command.TRANSLATE, -characterWidth * 64, characterHeight]);
    }
    return commands;
}
exports["default"] = generate;
var generateLookup = function (characterWidth, characterHeight) {
    return {
        0: {
            x: offsetX,
            y: offsetY,
            spriteWidth: 64 * characterWidth,
            spriteHeight: 32 * characterHeight
        }
    };
};
exports.generateLookup = generateLookup;
