"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateLookup = void 0;
var font_1 = require("./font");
var types_1 = require("./types");
var offsetX = 0;
var offsetY = 130;
function generate(font, characterWidth, characterHeight, colors) {
    return __spreadArray([
        [types_1.Command.RESET_TRANSFORM],
        [types_1.Command.TRANSLATE, offsetX, offsetY]
    ], __read(colors.feedbackScale.flatMap(function (color) {
        return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
            [types_1.Command.FILL_COLOR, colors.outputConnectorBackground],
            [types_1.Command.RECTANGLE, 0, 0, characterWidth * 3, characterHeight],
            [types_1.Command.FILL_COLOR, colors.outputConnector]
        ], __read((0, font_1.drawCharacter)(font, '[', characterWidth, characterHeight)), false), [
            [types_1.Command.TRANSLATE, characterWidth, 0],
            [types_1.Command.FILL_COLOR, color]
        ], false), __read((0, font_1.drawCharacter)(font, '*', characterWidth, characterHeight)), false), [
            [types_1.Command.TRANSLATE, characterWidth, 0],
            [types_1.Command.FILL_COLOR, colors.outputConnector]
        ], false), __read((0, font_1.drawCharacter)(font, ']', characterWidth, characterHeight)), false), [
            [types_1.Command.TRANSLATE, characterWidth, 0],
        ], false);
    })), false);
}
exports["default"] = generate;
var generateLookup = function (characterWidth, characterHeight, feedbackScale) {
    return Object.fromEntries(feedbackScale.map(function (color, index) {
        return [
            index,
            {
                x: offsetX + index * (characterWidth * 3),
                y: offsetY,
                spriteWidth: characterWidth * 3,
                spriteHeight: characterHeight
            },
        ];
    }));
};
exports.generateLookup = generateLookup;
