"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var errors_1 = require("../errors");
var use = function (line, context) {
    if (line.arguments[0].type !== "identifier" /* ArgumentType.IDENTIFIER */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    var namespaceToUse = context.namespace.namespaces.get(line.arguments[0].value);
    if (!namespaceToUse) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
    }
    context.namespace.consts = __assign(__assign({}, context.namespace.consts), namespaceToUse.consts);
    return context;
};
exports["default"] = use;
