"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var utils_1 = require("../utils");
exports["default"] = [
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([], __read((0, utils_1.same)(4, 6)), false), [0, 4], false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([], __read((0, utils_1.same)(10, 3)), false), __read((0, utils_1.same)(0, 5)), false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(10, 2)), false), [31], false), __read((0, utils_1.same)(10, 2)), false), [31], false), __read((0, utils_1.same)(10, 2)), false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([4], __read((0, utils_1.mirr)([14, 21], [20, 14, 5])), false), [4], false), 1),
    (0, utils_1.pad)(0, [8, 20, 9, 2, 4, 8, 18, 5, 2], 1),
    (0, utils_1.pad)(0, [12, 18, 16, 8, 21, 18, 18, 13], 2),
    (0, utils_1.pad)(0, [4, 4], 8),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([2, 4, 4, 8]), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([8, 4, 4, 2]), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([4, 21, 14]), 4),
    (0, utils_1.pad)(2, [4, 4, 31, 4, 4], 3),
    (0, utils_1.pad)(6, [12, 12, 4, 8], 0),
    (0, utils_1.pad)(4, [31], 5),
    (0, utils_1.pad)(6, [12, 12], 2),
    (0, utils_1.pad)(0, [1, 1, 2, 2, 4, 4, 8, 8], 2),
    /* 0-9 */
    (0, utils_1.pad)(0, (0, utils_1.mirr)([14, 17], [19, 21, 25, 17]), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([4, 12, 20], __read((0, utils_1.same)(4, 4)), false), [31], false), 2),
    (0, utils_1.pad)(0, [14, 17, 1, 2, 4, 8, 16, 31], 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([14, 17, 1], [6, 1]), 2),
    (0, utils_1.pad)(0, __spreadArray([2, 6, 10, 18, 31], __read((0, utils_1.same)(2, 3)), false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([31, 16, 30, 17], __read((0, utils_1.same)(1, 3)), false), [15], false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([6, 8, 16, 30], __read((0, utils_1.same)(17, 3)), false), [14], false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([31, 17, 1], __read((0, utils_1.same)(2, 2)), false), __read((0, utils_1.same)(4, 3)), false), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)(__spreadArray([14], __read((0, utils_1.same)(17, 2)), false), [14, 17]), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([14], __read((0, utils_1.same)(17, 3)), false), [15, 1, 2, 12], false), 2),
    (0, utils_1.pad)(2, [12, 12, 0, 0, 12, 12], 2),
    (0, utils_1.pad)(2, [12, 12, 0, 0, 12, 12, 4, 8], 0),
    (0, utils_1.pad)(2, __spreadArray([], __read((0, utils_1.mirr)([3, 12], [16])), false), 3),
    (0, utils_1.pad)(3, [31, 0, 31], 4),
    (0, utils_1.pad)(2, __spreadArray([], __read((0, utils_1.mirr)([24, 6], [1])), false), 3),
    (0, utils_1.pad)(0, [14, 17, 1, 2, 4, 4, 0, 4], 2),
    (0, utils_1.pad)(10, []),
    /* A-Z */
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(4, 3)), false), __read((0, utils_1.same)(10, 2)), false), [31], false), __read((0, utils_1.same)(17, 2)), false), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)(__spreadArray([30], __read((0, utils_1.same)(17, 2)), false), [30, 17]), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([14, 17], (0, utils_1.same)(16, 4)), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([28, 18], (0, utils_1.same)(17, 4)), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)(__spreadArray([31], __read((0, utils_1.same)(16, 2)), false), [30, 16]), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray([31], __read((0, utils_1.same)(16, 2)), false), [30], false), __read((0, utils_1.same)(16, 4)), false), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([14, 17], __spreadArray(__spreadArray([], __read((0, utils_1.same)(16, 2)), false), [19, 17], false)), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)((0, utils_1.same)(17, 3), [31, 17]), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([14], (0, utils_1.same)(4, 6)), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray([15], __read((0, utils_1.same)(1, 4)), false), __read((0, utils_1.same)(17, 2)), false), [14], false), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([17, 18, 20, 24]), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([], __read((0, utils_1.same)(16, 7)), false), [31], false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray([17], __read((0, utils_1.same)(27, 2)), false), [21], false), __read((0, utils_1.same)(17, 4)), false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray(__spreadArray([17], __read((0, utils_1.same)(25, 2)), false), __read((0, utils_1.same)(21, 2)), false), __read((0, utils_1.same)(19, 2)), false), [17], false), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([14], (0, utils_1.same)(17, 6)), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([], __read((0, utils_1.mirr)([30], (0, utils_1.same)(17, 3))), false), __read((0, utils_1.same)(16, 3)), false), 2),
    __spreadArray(__spreadArray([], __read((0, utils_1.mirr)([14], (0, utils_1.same)(17, 6))), false), [2, 1], false),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([], __read((0, utils_1.mirr)([30], (0, utils_1.same)(17, 3))), false), [20, 18, 17], false), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([14, 17], __spreadArray([16, 14], __read((0, utils_1.same)(1, 2)), false)), 2),
    (0, utils_1.pad)(0, __spreadArray([31], __read((0, utils_1.same)(4, 7)), false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 7)), false), [14], false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 4)), false), __read((0, utils_1.same)(10, 2)), false), __read((0, utils_1.same)(4, 2)), false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 2)), false), __read((0, utils_1.same)(21, 3)), false), __read((0, utils_1.same)(10, 3)), false), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)(__spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 2)), false), [10, 4], false)), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 2)), false), __read((0, utils_1.same)(10, 2)), false), __read((0, utils_1.same)(4, 4)), false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([31, 1, 2], __read((0, utils_1.same)(4, 2)), false), [8, 16, 63], false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([14], __read((0, utils_1.same)(8, 6)), false), [14], false), 2),
    (0, utils_1.pad)(0, [16, 16, 8, 8, 4, 4, 2, 2], 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([14], __read((0, utils_1.same)(2, 6)), false), [14], false), 2),
    (0, utils_1.pad)(0, [4, 10, 17], 7),
    (0, utils_1.pad)(7, [31], 2),
    (0, utils_1.pad)(0, [8, 4], 8),
    /* a-z */
    (0, utils_1.pad)(2, __spreadArray(__spreadArray([14, 17], __read((0, utils_1.same)(17, 2)), false), [19, 13], false), 2),
    (0, utils_1.pad)(0, [16, 16, 22, 25, 17, 17, 17, 30], 2),
    (0, utils_1.pad)(2, (0, utils_1.mirr)([14, 17], (0, utils_1.same)(16, 2)), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([1, 1, 15], __read((0, utils_1.same)(17, 3)), false), [19, 13], false), 2),
    (0, utils_1.pad)(2, [14, 17, 31, 16, 17, 14], 2),
    (0, utils_1.pad)(0, __spreadArray([6, 8, 30], __read((0, utils_1.same)(8, 5)), false), 2),
    (0, utils_1.pad)(2, __spreadArray(__spreadArray([15], __read((0, utils_1.same)(17, 3)), false), [19, 13, 0, 14], false)),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(16, 2)), false), [22, 25], false), __read((0, utils_1.same)(17, 4)), false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([4, 0, 12], __read((0, utils_1.same)(4, 4)), false), [6], false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([2, 0, 14], __read((0, utils_1.same)(2, 5)), false), [28], false), 1),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([], __read((0, utils_1.same)(16, 2)), false), [18, 20, 24, 20, 18, 17], false), 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray([], __read((0, utils_1.same)(4, 7)), false), [6], false), 2),
    (0, utils_1.pad)(2, __spreadArray([26], __read((0, utils_1.same)(21, 5)), false), 2),
    (0, utils_1.pad)(2, __spreadArray([22, 25], __read((0, utils_1.same)(17, 4)), false), 2),
    (0, utils_1.pad)(2, (0, utils_1.mirr)([14], (0, utils_1.same)(17, 4)), 2),
    (0, utils_1.pad)(2, __spreadArray(__spreadArray(__spreadArray([22, 25], __read((0, utils_1.same)(17, 3)), false), [30], false), __read((0, utils_1.same)(16, 2)), false)),
    (0, utils_1.pad)(2, __spreadArray(__spreadArray(__spreadArray([15], __read((0, utils_1.same)(17, 3)), false), [19, 13], false), __read((0, utils_1.same)(1, 2)), false)),
    (0, utils_1.pad)(2, __spreadArray(__spreadArray([27, 12], __read((0, utils_1.same)(8, 3)), false), [28], false), 2),
    (0, utils_1.pad)(2, [14, 17, 12, 2, 17, 14], 2),
    (0, utils_1.pad)(0, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(8, 2)), false), [30], false), __read((0, utils_1.same)(8, 3)), false), [9, 6], false), 2),
    (0, utils_1.pad)(2, __spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 4)), false), [19, 13], false), 2),
    (0, utils_1.pad)(2, __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 2)), false), __read((0, utils_1.same)(10, 2)), false), __read((0, utils_1.same)(4, 2)), false), 2),
    (0, utils_1.pad)(2, __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 2)), false), __read((0, utils_1.same)(21, 2)), false), __read((0, utils_1.same)(10, 2)), false), 2),
    (0, utils_1.pad)(2, (0, utils_1.mirr)([17, 10, 4]), 2),
    (0, utils_1.pad)(2, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(17, 2)), false), __read((0, utils_1.same)(10, 2)), false), __read((0, utils_1.same)(4, 2)), false), [8, 16], false)),
    (0, utils_1.pad)(2, [31, 2, 4, 8, 16, 63], 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([3, 4, 4], [8, 4]), 2),
    (0, utils_1.pad)(0, (0, utils_1.same)(4, 8), 2),
    (0, utils_1.pad)(0, (0, utils_1.mirr)([24, 4, 4], [2, 4]), 2),
    (0, utils_1.pad)(10, []), // ~
].flat();
