import { Project } from '../../../packages/editor/src/state/types';

const midiArpeggiator2: Project = {
	title: 'MIDI Generative Melody',
	author: 'Andor Polgar',
	description: '',
	codeBlocks: [
		{
			code: [
				'module bufferCombiner',
				'',
				'float* bufferA &chord1.notes',
				'int* lengthA &chord1.length',
				'float* bufferB &chord2.notes',
				'int* lengthB &mapToRange4.out',
				'',
				'float[] buffer 16',
				'int length',
				'',
				'int bufferPointer',
				'',
				'; Calculate combined length',
				'push &length',
				'push *lengthA',
				'push *lengthB',
				'add',
				'store',
				'',
				'; Reset buffer pointer',
				'push &bufferPointer',
				'push 0',
				'store',
				' ',
				'loop',
				' ; Guard ',
				' push bufferPointer',
				' push length',
				' greaterOrEqual',
				' branchIfTrue 1',
				' ',
				' ; Calculate destination',
				' ; buffer address',
				' push &buffer',
				' push bufferPointer',
				' push WORD_SIZE',
				' mul',
				' add',
				' ',
				' ; Calculate sorce',
				' ; buffer address',
				' push bufferPointer',
				' push *lengthA',
				' lessThan',
				' if int',
				'  push bufferA  ',
				'  push bufferPointer',
				'  push WORD_SIZE',
				'  mul',
				'  add',
				' else',
				'  push bufferB',
				'  push bufferPointer',
				'  push *lengthA',
				'  sub',
				'  push WORD_SIZE',
				'  mul',
				'  add',
				' ifEnd',
				'',
				' load ; value from src',
				' store ; value in dst',
				' ',
				' ; Increment buffer pointer',
				' push &bufferPointer',
				' push bufferPointer',
				' push 1',
				' add',
				' store',
				'loopEnd',
				'',
				'moduleEnd',
			],
			x: 34,
			y: 5,
			isOpen: true,
		},
		{
			code: [
				'module chord1',
				'float[] notes 12',
				'int length 3',
				'piano notes length 48',
				'init notes[0] 48.0',
				'init notes[1] 55.0',
				'init notes[2] 52.0',
				'moduleEnd',
			],
			x: -19,
			y: 4,
			isOpen: true,
		},
		{
			code: [
				'module chord2',
				'float[] notes 12',
				'int length 3',
				'piano notes length 48',
				'init notes[0] 67.0',
				'init notes[1] 60.0',
				'init notes[2] 65.0',
				'moduleEnd',
			],
			x: -19,
			y: 17,
			isOpen: true,
		},
		{
			code: [
				'module clock',
				'',
				'const HIGH 1',
				'const LOW 0',
				'',
				'int out',
				'',
				'push &out',
				'push out',
				'push HIGH',
				'equal',
				'if int',
				' push LOW',
				'else',
				' push HIGH',
				'ifEnd',
				'store',
				'',
				'moduleEnd',
			],
			x: 30,
			y: -41,
			isOpen: true,
		},
		{
			code: [
				'module clockDivider',
				'',
				'int default 8',
				'int* divider &default',
				'int* trigger &clock.out',
				'int out',
				'int counter',
				'',
				'debug out',
				'',
				'; Avoid remainder by zero',
				'push *divider',
				'push 0',
				'equal',
				'branchIfTrue 0',
				'',
				'push *trigger',
				'risingEdge',
				'if void',
				' push &counter',
				' push counter',
				' push 1',
				' add',
				' store ',
				'ifEnd',
				'',
				'push counter',
				'push *divider',
				'remainder',
				'push 0',
				'equal',
				'if void',
				' push &out',
				' push *trigger',
				' store',
				'else',
				' push &out',
				' push 0',
				' store',
				'ifEnd',
				'',
				'moduleEnd',
			],
			x: 64,
			y: -41,
			isOpen: true,
		},
		{
			code: [
				'module lcg',
				'; Linear congruential ',
				'; generator',
				'',
				'const multiplier 1664525',
				'const increment 1013904223 ',
				'const modulus 65536 ; 2^16',
				'int seed 69420',
				'',
				'float out',
				'',
				'push &seed',
				' push multiplier',
				' push seed',
				' mul',
				' push increment',
				' add',
				' push modulus',
				' remainder',
				'store',
				'',
				'push &out',
				' push seed',
				' castToFloat',
				' push modulus',
				' castToFloat',
				' div ',
				'store',
				'',
				'moduleEnd',
			],
			x: -89,
			y: -31,
			isOpen: true,
		},
		{
			code: [
				'module lcg2',
				'; Linear congruential ',
				'; generator',
				'',
				'const multiplier 1664525',
				'const increment 1013904223 ',
				'const modulus 65536 ; 2^16',
				'int seed 69421',
				'',
				'float out',
				'',
				'push &seed',
				' push multiplier',
				' push seed',
				' mul',
				' push increment',
				' add',
				' push modulus',
				' remainder',
				'store',
				'',
				'push &out',
				' push seed',
				' castToFloat',
				' push modulus',
				' castToFloat',
				' div ',
				'store',
				'',
				'moduleEnd',
			],
			x: -125,
			y: -31,
			isOpen: true,
		},
		{
			code: [
				'module mapToRange2',
				'',
				'const IN_RANGE_MIN -1.0',
				'const IN_RANGE_MAX 1.0',
				'',
				'float min 48.0',
				'float max 92.0',
				'',
				'float* in &lcg.out',
				'float* outRangeMin &min',
				'float* outRangeMax &max',
				'float out',
				'',
				'debug out',
				'',
				'push &out',
				'push *in',
				'push IN_RANGE_MIN',
				'sub',
				'push IN_RANGE_MAX',
				'push IN_RANGE_MIN',
				'sub',
				'div',
				'push *outRangeMax',
				'push *outRangeMin',
				'sub',
				'mul',
				'push *outRangeMin',
				'add',
				'store',
				'',
				'moduleEnd',
			],
			x: -18,
			y: -31,
			isOpen: true,
		},
		{
			code: [
				'module mapToRange4',
				'',
				'const IN_RANGE_MIN -1.0',
				'const IN_RANGE_MAX 1.0',
				'const OUT_RANGE_MIN 0.0',
				'const OUT_RANGE_MAX 4.0',
				'',
				'float* in &triangle2.out',
				'int out',
				'',
				'debug out',
				'',
				'push &out',
				'push *in',
				'push IN_RANGE_MIN',
				'sub',
				'push IN_RANGE_MAX',
				'push IN_RANGE_MIN',
				'sub',
				'div',
				'push OUT_RANGE_MAX',
				'push OUT_RANGE_MIN',
				'sub',
				'mul',
				'push OUT_RANGE_MIN',
				'add',
				'round',
				'castToInt',
				'store',
				'',
				'moduleEnd',
			],
			x: -55,
			y: 6,
			isOpen: true,
		},
		{
			code: [
				'module mapToRange5',
				'',
				'const IN_RANGE_MIN -1.0',
				'const IN_RANGE_MAX 1.0',
				'const OUT_RANGE_MIN 10.0',
				'const OUT_RANGE_MAX 127.0',
				'',
				'float* in &lcg2.out',
				'int out',
				'',
				'debug out',
				'',
				'push &out',
				'push *in',
				'push IN_RANGE_MIN',
				'sub',
				'push IN_RANGE_MAX',
				'push IN_RANGE_MIN',
				'sub',
				'div',
				'push OUT_RANGE_MAX',
				'push OUT_RANGE_MIN',
				'sub',
				'mul',
				'push OUT_RANGE_MIN',
				'add',
				'round',
				'castToInt',
				'store',
				'',
				'moduleEnd',
			],
			x: -53,
			y: -31,
			isOpen: true,
		},
		{
			code: [
				'module midinoteout',
				'',
				'float* noteIn &quantizer.out',
				'int channel 1',
				'int* gate &clockDivider.out',
				'int* velocity &mapToRange5.out',
				'int note ',
				'',
				'push &note',
				'push *noteIn',
				'castToInt',
				'store',
				'',
				'moduleEnd',
			],
			x: 20,
			y: -13,
			isOpen: true,
		},
		{
			code: [
				'module quantizer',
				'',
				'float* in &mapToRange2.out',
				'float* buffer &replicator.buffer',
				'int* length &replicator.length',
				'float out',
				'',
				'debug out',
				'',
				'float* _levelPointer',
				'float _difference',
				'float _smallestDiff 1000',
				'',
				'debug &_smallestDiff',
				'',
				'push &_smallestDiff',
				'push 1000.0',
				'store',
				'',
				'push &_levelPointer',
				'push buffer',
				'store',
				'',
				'loop',
				' ; Calculate difference ',
				' ; between the input and',
				' ; the current level.',
				' push &_difference',
				' push *_levelPointer',
				' push *in',
				' sub',
				' abs',
				' store',
				'',
				' push _difference',
				' push _smallestDiff',
				' lessOrEqual',
				' if void',
				"  ; If it's actually smaller",
				'  ; than the smallest difference,',
				'  ; then update the smallest ',
				'  ; difference.',
				'  push &_smallestDiff',
				'  push _difference',
				'  store',
				'  ; Save the current level value.',
				'  push &out',
				'  push *_levelPointer',
				'  store',
				' ifEnd',
				'',
				' ; Guard',
				' push _levelPointer',
				' push buffer',
				' push *length',
				' push 1',
				' sub',
				' push WORD_SIZE',
				' mul',
				' add',
				' greaterOrEqual',
				' branchIfTrue 1 ',
				'',
				' ; Increment level pointer',
				' push &_levelPointer',
				' push _levelPointer',
				' push WORD_SIZE',
				' add',
				' store',
				'loopEnd',
				'',
				'moduleEnd',
			],
			x: 119,
			y: 5,
			isOpen: true,
		},
		{
			code: [
				'module replicator',
				'',
				'const TIMES 3',
				'const OFFSET 24.0',
				'',
				'float* bufferIn &bufferCombiner.buffer',
				'int* lengthIn &bufferCombiner.length',
				'float[] buffer 64 ; max size',
				'int length',
				'int _pointer',
				' ',
				'debug length',
				'',
				'plot buffer 0 127 length',
				'',
				'push &length',
				'push *lengthIn',
				'push TIMES',
				'mul',
				'store',
				'',
				'push &_pointer',
				'push 0',
				'store',
				'',
				'; To avoid remainder by zero',
				'push length',
				'push 0',
				'equal',
				'branchIfTrue 0',
				'',
				'loop ',
				' ; Calculate destination',
				' ; address',
				' push &buffer',
				' push _pointer',
				' push WORD_SIZE',
				' mul',
				' add',
				'',
				' ; Calculate source',
				' ; address',
				' push bufferIn',
				' push _pointer',
				' push *lengthIn',
				' remainder',
				' push WORD_SIZE',
				' mul',
				' add',
				'  ',
				' loadFloat',
				'',
				' push _pointer',
				' push *lengthIn',
				' div',
				' castToFloat',
				' push OFFSET',
				' mul',
				' add',
				'',
				' store ; value to dst',
				'',
				' ; Guard',
				' push _pointer',
				' push length',
				' push 1',
				' sub',
				' greaterOrEqual',
				' branchIfTrue 1',
				' ',
				' ; Increment buffer pointer',
				' push &_pointer',
				' push _pointer',
				' push 1',
				' add',
				' store',
				'loopEnd',
				'',
				'moduleEnd',
			],
			x: 72,
			y: 5,
			isOpen: true,
		},
		{
			code: [
				'module triangle2',
				'',
				'float default 0.3 ;Hz ',
				'float* frequency &default',
				'float out',
				'float direction 1 ',
				'',
				'push &out',
				'push out',
				'push *frequency',
				'push 2.0',
				'mul',
				'push SAMPLE_RATE',
				'castToFloat',
				'div',
				'push direction',
				'mul',
				'add',
				'store',
				'',
				'push out',
				'push 1.0',
				'greaterThan',
				'if void',
				' push &direction',
				' push -1.0',
				' store',
				'ifEnd',
				'',
				'push out',
				'push -1.0',
				'lessThan',
				'if void',
				' push &direction',
				' push 1.0',
				' store',
				'ifEnd',
				'',
				'moduleEnd',
			],
			x: -89,
			y: 2,
			isOpen: true,
		},
	],
	viewport: { x: -90, y: -12 },
	selectedRuntime: 0,
	runtimeSettings: [
		{
			runtime: 'WebWorkerMIDIRuntime',
			sampleRate: 50,
		},
	],
};

export default midiArpeggiator2;
