import { Project } from '../../../packages/editor/src/state/types';

const project: Project = {
	title: 'Audio Loopback',
	author: 'Andor Polgar',
	description: '',
	codeBlocks: [
		{
			code: [
				'module audioin',
				'',
				'; Audio buffer',
				'float[] buffer AUDIO_BUFFER_SIZE',
				'float out',
				'int pointer &buffer',
				'',
				'debug out',
				'',
				'plot buffer -2 2',
				'',
				'push &out',
				'push pointer',
				'loadFloat',
				'store',
				'',
				'push &pointer ',
				'push pointer',
				'push WORD_SIZE',
				'add',
				'store',
				'',
				'push pointer',
				'push buffer&',
				'greaterThan',
				'if void',
				' push &pointer',
				' push &buffer',
				' store',
				'ifEnd',
				'',
				'moduleEnd',
			],
			x: 6,
			y: 3,
			isOpen: true,
		},
		{
			code: [
				'module audiooutL',
				'',
				'float* in &audioin.out',
				'',
				'; Audio buffer',
				'float[] buffer AUDIO_BUFFER_SIZE',
				'int pointer &buffer',
				'',
				'plot buffer -2 2',
				'',
				'; Store the input value',
				'; in the buffer',
				'push pointer',
				'push *in',
				'store',
				'',
				'; Increment the buffer ',
				'; pointer by the word size',
				'; which is 4 bytes',
				'push &pointer',
				'push pointer',
				'push WORD_SIZE',
				'add',
				'store',
				'',
				'; Reset the buffer pointer',
				'; when it reaches the end',
				'; of the buffer',
				'push pointer',
				'push buffer&',
				'greaterThan',
				'if void',
				' push &pointer',
				' push &buffer',
				' store',
				'ifEnd',
				'',
				'moduleEnd',
			],
			x: 48,
			y: 1,
			isOpen: true,
		},
		{
			code: [
				'module audiooutR',
				'',
				'float* in &audioin.out',
				'',
				'; Audio buffer',
				'float[] buffer AUDIO_BUFFER_SIZE',
				'int pointer &buffer',
				'',
				'plot buffer -2 2',
				'',
				'; Store the input value',
				'; in the buffer',
				'push pointer',
				'push *in',
				'store',
				'',
				'; Increment the buffer ',
				'; pointer by the word size',
				'; which is 4 bytes',
				'push &pointer',
				'push pointer',
				'push WORD_SIZE',
				'add',
				'store',
				'',
				'; Reset the buffer pointer',
				'; when it reaches the end',
				'; of the buffer',
				'push pointer',
				'push buffer&',
				'greaterThan',
				'if void',
				' push &pointer',
				' push &buffer',
				' store',
				'ifEnd',
				'',
				'moduleEnd',
			],
			x: 90,
			y: 1,
			isOpen: true,
		},
	],
	viewport: { x: -17, y: -3 },
	selectedRuntime: 0,
	runtimeSettings: [
		{
			sampleRate: 44100,
			runtime: 'AudioWorkletRuntime',
			audioOutputBuffers: [
				{ moduleId: 'audiooutL', memoryId: 'buffer', channel: 0, output: 0 },
				{ moduleId: 'audiooutR', memoryId: 'buffer', channel: 1, output: 0 },
			],
			audioInputBuffers: [{ moduleId: 'audioin', memoryId: 'buffer', channel: 0, input: 0 }],
		},
	],
	binaryAssets: [],
};

export default project;
