"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var _const = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    if (!line.arguments[0] || !line.arguments[1]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type === "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    var value = { value: 0, isInteger: true };
    if (line.arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */) {
        if (typeof context.namespace.consts[line.arguments[1].value] !== 'undefined') {
            value = context.namespace.consts[line.arguments[1].value];
        }
        else {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
    }
    else {
        value = line.arguments[1];
    }
    context.namespace.consts[line.arguments[0].value] = value;
    return context;
};
exports["default"] = _const;
