"use strict";
exports.__esModule = true;
exports.getError = exports.ErrorCode = void 0;
var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["INSUFFICIENT_OPERANDS"] = 0] = "INSUFFICIENT_OPERANDS";
    ErrorCode[ErrorCode["UNMATCHING_OPERANDS"] = 1] = "UNMATCHING_OPERANDS";
    ErrorCode[ErrorCode["ONLY_INTEGERS"] = 2] = "ONLY_INTEGERS";
    ErrorCode[ErrorCode["ONLY_FLOATS"] = 3] = "ONLY_FLOATS";
    ErrorCode[ErrorCode["MISSING_ARGUMENT"] = 4] = "MISSING_ARGUMENT";
    ErrorCode[ErrorCode["UNDECLARED_IDENTIFIER"] = 5] = "UNDECLARED_IDENTIFIER";
    ErrorCode[ErrorCode["EXPECTED_IDENTIFIER"] = 6] = "EXPECTED_IDENTIFIER";
    ErrorCode[ErrorCode["EXPECTED_INTEGER_OPERAND"] = 7] = "EXPECTED_INTEGER_OPERAND";
    ErrorCode[ErrorCode["UNRECOGNISED_INSTRUCTION"] = 8] = "UNRECOGNISED_INSTRUCTION";
    ErrorCode[ErrorCode["EXPECTED_VALUE"] = 9] = "EXPECTED_VALUE";
    ErrorCode[ErrorCode["MISSING_MODULE_ID"] = 10] = "MISSING_MODULE_ID";
    ErrorCode[ErrorCode["EXPECTED_FLOAT_OPERAND"] = 11] = "EXPECTED_FLOAT_OPERAND";
    ErrorCode[ErrorCode["UNKNOWN_ERROR"] = 12] = "UNKNOWN_ERROR";
    ErrorCode[ErrorCode["STACK_EXPECTED_ZERO_ELEMENTS"] = 13] = "STACK_EXPECTED_ZERO_ELEMENTS";
    ErrorCode[ErrorCode["MISSING_BLOCK_START_INSTRUCTION"] = 14] = "MISSING_BLOCK_START_INSTRUCTION";
    ErrorCode[ErrorCode["INSTRUCTION_INVALID_OUTSIDE_BLOCK"] = 15] = "INSTRUCTION_INVALID_OUTSIDE_BLOCK";
    ErrorCode[ErrorCode["DIVISION_BY_ZERO"] = 16] = "DIVISION_BY_ZERO";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
function getError(code, line, context) {
    switch (code) {
        case ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK:
            return {
                code: code,
                message: 'This instruction can only be used within a block or a module. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.INSUFFICIENT_OPERANDS:
            return {
                code: code,
                message: 'Insufficient number of elements in the stack for the operation to proceed. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.UNMATCHING_OPERANDS:
            return {
                code: code,
                message: 'This instruction can only operate on operands of the same type. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.ONLY_INTEGERS:
            return {
                code: code,
                message: 'The operation only accepts integer values as operands. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.ONLY_FLOATS:
            return {
                code: code,
                message: 'The operation only accepts float values as operands. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.MISSING_ARGUMENT:
            return {
                code: code,
                message: 'Missing argument. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.UNDECLARED_IDENTIFIER:
            return {
                code: code,
                message: 'Undeclared identifier. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.EXPECTED_IDENTIFIER:
            return {
                code: code,
                message: 'Expected identifier, got a value. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.EXPECTED_INTEGER_OPERAND:
            return {
                code: code,
                message: 'Expected one of the operands to be an integer value. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.UNRECOGNISED_INSTRUCTION:
            return {
                code: code,
                message: 'Unrecognised instruction. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.EXPECTED_VALUE:
            return {
                code: code,
                message: 'Expected value, got an identifier. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.MISSING_MODULE_ID:
            return {
                code: code,
                message: 'Missing module ID. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.EXPECTED_FLOAT_OPERAND:
            return {
                code: code,
                message: 'Expected one of the operands to be a floating point value. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.STACK_EXPECTED_ZERO_ELEMENTS:
            return {
                code: code,
                message: line.lineNumber +
                    ': Expected 0 elements on the stack, found ' +
                    (context === null || context === void 0 ? void 0 : context.stack.length) +
                    ' [' +
                    (context === null || context === void 0 ? void 0 : context.stack.map(function (_a) {
                        var isInteger = _a.isInteger;
                        return (isInteger ? 'int' : 'float');
                    }).join(', ')) +
                    '] (' +
                    code +
                    ')',
                line: line,
                context: context
            };
        case ErrorCode.MISSING_BLOCK_START_INSTRUCTION:
            return {
                code: code,
                message: 'Missing block start instruction. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.DIVISION_BY_ZERO:
            return {
                code: code,
                message: 'Possible division by zero. (' + code + ')',
                line: line,
                context: context
            };
        case ErrorCode.UNKNOWN_ERROR:
        default:
            return {
                message: 'Unknown error (' + code + ')',
                code: code,
                line: line,
                context: context
            };
    }
}
exports.getError = getError;
