"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.saveByteCode = exports.areAllOperandsFloats = exports.areAllOperandsIntegers = exports.calculateWordAlignedSizeOfMemory = exports.isInstructionIsInsideBlock = exports.isInstructionIsInsideAModule = exports.getElementWordSize = exports.getElementCount = exports.getMemoryStringLastByteAddress = exports.getDataStructureByteAddress = exports.getDataStructure = exports.isElementWordSizeIdentifier = exports.isElementCountIdentifier = exports.isMemoryPointerIdentifier = exports.isMemoryReferenceIdentifier = exports.isMemoryIdentifier = void 0;
var consts_1 = require("./consts");
var types_1 = require("./types");
function isMemoryIdentifier(memoryMap, name) {
    return memoryMap.has(name);
}
exports.isMemoryIdentifier = isMemoryIdentifier;
function isMemoryReferenceIdentifier(memoryMap, name) {
    return ((name.startsWith('&') && memoryMap.has(name.substring(1))) ||
        (name.endsWith('&') && memoryMap.has(name.slice(0, -1))));
}
exports.isMemoryReferenceIdentifier = isMemoryReferenceIdentifier;
function isMemoryPointerIdentifier(memoryMap, name) {
    return name.startsWith('*') && memoryMap.has(name.substring(1));
}
exports.isMemoryPointerIdentifier = isMemoryPointerIdentifier;
function isElementCountIdentifier(memoryMap, name) {
    return name.startsWith('$') && memoryMap.has(name.substring(1));
}
exports.isElementCountIdentifier = isElementCountIdentifier;
function isElementWordSizeIdentifier(memoryMap, name) {
    return name.startsWith('%') && memoryMap.has(name.substring(1));
}
exports.isElementWordSizeIdentifier = isElementWordSizeIdentifier;
function getDataStructure(memoryMap, id) {
    return memoryMap.get(id);
}
exports.getDataStructure = getDataStructure;
function getDataStructureByteAddress(memoryMap, id) {
    var memoryItem = getDataStructure(memoryMap, id);
    return memoryItem ? memoryItem.byteAddress : 0;
}
exports.getDataStructureByteAddress = getDataStructureByteAddress;
function getMemoryStringLastByteAddress(memoryMap, id) {
    var memoryItem = getDataStructure(memoryMap, id);
    return memoryItem ? memoryItem.byteAddress + (memoryItem.wordAlignedSize - 1) * consts_1.GLOBAL_ALIGNMENT_BOUNDARY : 0;
}
exports.getMemoryStringLastByteAddress = getMemoryStringLastByteAddress;
function getElementCount(memoryMap, id) {
    var memoryItem = getDataStructure(memoryMap, id);
    return memoryItem ? memoryItem.numberOfElements : 0;
}
exports.getElementCount = getElementCount;
function getElementWordSize(memoryMap, id) {
    var memoryItem = getDataStructure(memoryMap, id);
    return memoryItem ? memoryItem.elementWordSize : 0;
}
exports.getElementWordSize = getElementWordSize;
function isInstructionIsInsideAModule(blockStack) {
    for (var i = blockStack.length - 1; i >= 0; i--) {
        if (blockStack[i].blockType === types_1.BLOCK_TYPE.MODULE) {
            return true;
        }
    }
    return false;
}
exports.isInstructionIsInsideAModule = isInstructionIsInsideAModule;
function isInstructionIsInsideBlock(blockStack, blockType) {
    for (var i = blockStack.length - 1; i >= 0; i--) {
        if (blockStack[i].blockType === blockType) {
            return true;
        }
    }
    return false;
}
exports.isInstructionIsInsideBlock = isInstructionIsInsideBlock;
function calculateWordAlignedSizeOfMemory(memory) {
    return Array.from(memory.values()).reduce(function (accumulator, current) {
        return accumulator + current.wordAlignedSize;
    }, 0);
}
exports.calculateWordAlignedSizeOfMemory = calculateWordAlignedSizeOfMemory;
function areAllOperandsIntegers() {
    var operands = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        operands[_i] = arguments[_i];
    }
    return !operands.some(function (operand) { return !operand.isInteger; });
}
exports.areAllOperandsIntegers = areAllOperandsIntegers;
function areAllOperandsFloats() {
    var operands = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        operands[_i] = arguments[_i];
    }
    return !operands.some(function (operand) { return operand.isInteger; });
}
exports.areAllOperandsFloats = areAllOperandsFloats;
function saveByteCode(context, byteCode) {
    var _a, _b;
    if (isInstructionIsInsideBlock(context.blockStack, types_1.BLOCK_TYPE.INIT)) {
        (_a = context.initSegmentByteCode).push.apply(_a, __spreadArray([], __read(byteCode), false));
    }
    else {
        (_b = context.loopSegmentByteCode).push.apply(_b, __spreadArray([], __read(byteCode), false));
    }
    return context;
}
exports.saveByteCode = saveByteCode;
