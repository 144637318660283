"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateLookup = exports.PianoKey = void 0;
var types_1 = require("./types");
var font_1 = require("./font");
var types_2 = require("./fonts/types");
function whiteKeyLeft(state, font, characterWidth, characterHeight, colors) {
    return __spreadArray([
        state === 0 /* State.PRESSED */
            ? [types_1.Command.FILL_COLOR, colors.pianoKeyWhitePressed]
            : [types_1.Command.FILL_COLOR, state === 2 /* State.HIGHLIGHTED */ ? colors.pianoKeyWhiteHighlighted : colors.pianoKeyWhite]
    ], __read((0, font_1.drawCharacterMatrix)(font, characterWidth, characterHeight, [
        [types_2.Glyph.FILL, types_2.Glyph.THICK_LINE_LEFT],
        [types_2.Glyph.FILL, types_2.Glyph.THICK_LINE_LEFT],
        state === 1 /* State.NORMAL */ ? [types_2.Glyph.FILL, types_2.Glyph.FILL] : [types_2.Glyph.SLASH, types_2.Glyph.SLASH],
        state === 1 /* State.NORMAL */ ? [types_2.Glyph.FILL, types_2.Glyph.FILL] : [types_2.Glyph.SLASH, types_2.Glyph.SLASH],
    ])), false);
}
function blackKey(state, font, characterWidth, characterHeight, colors) {
    return __spreadArray([
        state === 0 /* State.PRESSED */
            ? [types_1.Command.FILL_COLOR, colors.pianoKeyBlackPressed]
            : [
                types_1.Command.FILL_COLOR,
                state === 2 /* State.HIGHLIGHTED */ ? colors.pianoKeyBlackHighlighted : colors.pianoKeyBlack,
                // eslint-disable-next-line no-mixed-spaces-and-tabs
            ]
    ], __read((0, font_1.drawCharacterMatrix)(font, characterWidth, characterHeight, [
        state === 1 /* State.NORMAL */ ? [types_2.Glyph.FILL, types_2.Glyph.FILL] : [types_2.Glyph.SLASH, types_2.Glyph.SLASH],
        state === 1 /* State.NORMAL */ ? [types_2.Glyph.FILL, types_2.Glyph.FILL] : [types_2.Glyph.SLASH, types_2.Glyph.SLASH],
        [types_2.Glyph.SPACE, types_2.Glyph.THICK_LINE_LEFT],
        [types_2.Glyph.SPACE, types_2.Glyph.THICK_LINE_LEFT],
    ])), false);
}
function whiteKeyMiddle(state, font, characterWidth, characterHeight, colors) {
    return __spreadArray([
        state === 0 /* State.PRESSED */
            ? [types_1.Command.FILL_COLOR, colors.pianoKeyWhitePressed]
            : [types_1.Command.FILL_COLOR, state === 2 /* State.HIGHLIGHTED */ ? colors.pianoKeyWhiteHighlighted : colors.pianoKeyWhite]
    ], __read((0, font_1.drawCharacterMatrix)(font, characterWidth, characterHeight, [
        [types_2.Glyph.THICK_LINE_RIGHT, types_2.Glyph.THICK_LINE_LEFT],
        [types_2.Glyph.THICK_LINE_RIGHT, types_2.Glyph.THICK_LINE_LEFT],
        state === 1 /* State.NORMAL */ ? [types_2.Glyph.FILL, types_2.Glyph.FILL] : [types_2.Glyph.SLASH, types_2.Glyph.SLASH],
        state === 1 /* State.NORMAL */ ? [types_2.Glyph.FILL, types_2.Glyph.FILL] : [types_2.Glyph.SLASH, types_2.Glyph.SLASH],
    ])), false);
}
function whiteKeyRight(state, font, characterWidth, characterHeight, colors) {
    return __spreadArray([
        state === 0 /* State.PRESSED */
            ? [types_1.Command.FILL_COLOR, colors.pianoKeyWhitePressed]
            : [types_1.Command.FILL_COLOR, state === 2 /* State.HIGHLIGHTED */ ? colors.pianoKeyWhiteHighlighted : colors.pianoKeyWhite]
    ], __read((0, font_1.drawCharacterMatrix)(font, characterWidth, characterHeight, [
        [types_2.Glyph.THICK_LINE_RIGHT, types_2.Glyph.FILL],
        [types_2.Glyph.THICK_LINE_RIGHT, types_2.Glyph.FILL],
        state === 1 /* State.NORMAL */ ? [types_2.Glyph.FILL, types_2.Glyph.FILL] : [types_2.Glyph.SLASH, types_2.Glyph.SLASH],
        state === 1 /* State.NORMAL */ ? [types_2.Glyph.FILL, types_2.Glyph.FILL] : [types_2.Glyph.SLASH, types_2.Glyph.SLASH],
    ])), false);
}
var orderedKeys = [
    whiteKeyLeft,
    blackKey,
    whiteKeyMiddle,
    blackKey,
    whiteKeyRight,
    whiteKeyLeft,
    blackKey,
    whiteKeyMiddle,
    blackKey,
    whiteKeyMiddle,
    blackKey,
    whiteKeyRight,
];
var offsetX = 0;
var offsetY = 200;
function stringToCharCodeArray(str) {
    var arr = [];
    for (var i = 0; i < str.length; i++) {
        arr.push(str.charCodeAt(i));
    }
    return arr;
}
function drawPianoKeyboard(state, glyphFont, asciiFont, characterWidth, characterHeight, colors) {
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        [types_1.Command.SAVE],
        [types_1.Command.FILL_COLOR, state === 2 /* State.HIGHLIGHTED */ ? colors.pianoKeyboardNoteHighlighted : colors.pianoKeyboardNote]
    ], __read((0, font_1.drawCharacterMatrix)(asciiFont, characterWidth, characterHeight, [
        stringToCharCodeArray('C C#D D#E F F#G G#A A#B'),
    ])), false), [
        [types_1.Command.TRANSLATE, 0, characterHeight]
    ], false), __read(orderedKeys
        .map(function (keyDrawerFunction) { return __spreadArray(__spreadArray([], __read(keyDrawerFunction(state, glyphFont, characterWidth, characterHeight, colors)), false), [
        [types_1.Command.TRANSLATE, characterHeight, 0],
    ], false); })
        .flat(1)), false), [
        [types_1.Command.RESTORE],
    ], false);
}
function generate(glyphFont, asciiFont, characterWidth, characterHeight, colors) {
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
        [types_1.Command.RESET_TRANSFORM],
        [types_1.Command.TRANSLATE, offsetX, offsetY],
        [types_1.Command.FILL_COLOR, colors.pianoKeyboardBackground],
        [types_1.Command.RECTANGLE, 0, 0, orderedKeys.length * characterHeight * 3, 80]
    ], __read(drawPianoKeyboard(1 /* State.NORMAL */, glyphFont, asciiFont, characterWidth, characterHeight, colors)), false), [
        [types_1.Command.TRANSLATE, characterHeight * orderedKeys.length, 0]
    ], false), __read(drawPianoKeyboard(0 /* State.PRESSED */, glyphFont, asciiFont, characterWidth, characterHeight, colors)), false), [
        [types_1.Command.TRANSLATE, characterHeight * orderedKeys.length, 0]
    ], false), __read(drawPianoKeyboard(2 /* State.HIGHLIGHTED */, glyphFont, asciiFont, characterWidth, characterHeight, colors)), false);
}
exports["default"] = generate;
var PianoKey;
(function (PianoKey) {
    PianoKey[PianoKey["NORMAL"] = 1000] = "NORMAL";
    PianoKey[PianoKey["PRESSED"] = 2000] = "PRESSED";
    PianoKey[PianoKey["HIGHLIGHTED"] = 3000] = "HIGHLIGHTED";
})(PianoKey = exports.PianoKey || (exports.PianoKey = {}));
var generateLookup = function (characterWidth, characterHeight) {
    return __assign({}, Object.fromEntries(new Array(24).fill(0).map(function (value, index) {
        return [
            index,
            {
                x: offsetX + index * characterWidth * 2,
                y: offsetY,
                spriteWidth: characterWidth * 2,
                spriteHeight: characterHeight * 5
            },
        ];
    })));
};
exports.generateLookup = generateLookup;
