"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.createVector = exports.encodeString = exports.signedLEB128 = exports.unsignedLEB128 = exports.ieee754 = void 0;
var ieee754 = function (n) {
    var buf = Buffer.allocUnsafe(4);
    buf.writeFloatLE(n, 0);
    return Uint8Array.from(buf);
};
exports.ieee754 = ieee754;
/**
 * LEB128 or Little Endian Base 128 is a variable-lenght code compression format like VLQ.
 * WebAssembly is using it's unsigned version for encoding all integer literals.
 */
function unsignedLEB128(n) {
    var buffer = [];
    do {
        var byte = n & 127;
        n >>>= 7;
        if (n !== 0) {
            byte |= 128;
        }
        buffer.push(byte);
    } while (n !== 0);
    return buffer;
}
exports.unsignedLEB128 = unsignedLEB128;
function signedLEB128(n) {
    var buffer = [];
    var more = true;
    var isNegative = n < 0;
    var bitCount = Math.ceil(Math.log2(Math.abs(n))) + 1;
    while (more) {
        var byte = n & 127;
        n >>= 7;
        if (isNegative) {
            n = n | -(1 << (bitCount - 8));
        }
        if ((n === 0 && (byte & 64) === 0) || (n === -1 && (byte & 64) == 64)) {
            more = false;
        }
        else {
            byte |= 128;
        }
        buffer.push(byte);
    }
    return buffer;
}
exports.signedLEB128 = signedLEB128;
function encodeString(str) {
    return __spreadArray(__spreadArray([], __read(unsignedLEB128(str.length)), false), __read(str.split('').map(function (char) { return char.charCodeAt(0); })), false);
}
exports.encodeString = encodeString;
function createVector(data) {
    return __spreadArray(__spreadArray([], __read(unsignedLEB128(data.length)), false), __read(data), false);
}
exports.createVector = createVector;
