"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var consts_1 = require("../consts");
var buffer = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    if (!line.arguments[0] || !line.arguments[1]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type === "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    var wordAlignedAddress = (0, utils_1.calculateWordAlignedSizeOfMemory)(context.namespace.memory);
    var numberOfElements = 1;
    var elementWordSize = line.instruction.includes('8') ? 1 : line.instruction.includes('16') ? 2 : 4;
    if (line.arguments[1].type === "literal" /* ArgumentType.LITERAL */) {
        numberOfElements = line.arguments[1].value;
    }
    else {
        var constant = context.namespace.consts[line.arguments[1].value];
        if (!constant) {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
        numberOfElements = constant.value;
    }
    context.namespace.memory.set(line.arguments[0].value, {
        numberOfElements: numberOfElements,
        elementWordSize: elementWordSize,
        wordAlignedSize: Math.ceil(numberOfElements * elementWordSize) / consts_1.GLOBAL_ALIGNMENT_BOUNDARY,
        wordAlignedAddress: context.startingByteAddress / consts_1.GLOBAL_ALIGNMENT_BOUNDARY + wordAlignedAddress,
        id: line.arguments[0].value,
        byteAddress: context.startingByteAddress + wordAlignedAddress * consts_1.GLOBAL_ALIGNMENT_BOUNDARY,
        "default": new Map(),
        isInteger: line.instruction.startsWith('int') || line.instruction.includes('*'),
        isPointer: line.instruction.includes('*'),
        isPointingToInteger: line.instruction.startsWith('int') && line.instruction.includes('*'),
        isPointingToPointer: line.instruction.includes('**'),
        type: line.instruction.slice(0, -2)
    });
    return context;
};
exports["default"] = buffer;
