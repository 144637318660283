"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var compiler_1 = require("../compiler");
var branchIfUnchanged = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand = context.stack.pop();
    if (!operand) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    context.stack.push(operand);
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type !== "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_VALUE, line, context);
    }
    var depth = line.arguments[0].value;
    var type = operand.isInteger ? 'int' : 'float';
    var previousValueMemoryName = '__branchIfUnchanged_previousValue' + line.lineNumber;
    var currentValueMemoryName = '__branchIfUnchanged_currentValue' + line.lineNumber;
    return (0, compiler_1.compileSegment)([
        "".concat(type, " ").concat(previousValueMemoryName, " 0"),
        "local ".concat(type, " ").concat(currentValueMemoryName),
        "localSet ".concat(currentValueMemoryName, " "),
        "push ".concat(previousValueMemoryName),
        "localGet ".concat(currentValueMemoryName),
        'equal',
        "branchIfTrue ".concat(depth),
        "push &".concat(previousValueMemoryName),
        "localGet ".concat(currentValueMemoryName),
        'store',
    ], context);
};
exports["default"] = branchIfUnchanged;
