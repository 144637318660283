"use strict";
exports.__esModule = true;
exports.ExportDesc = exports.ImportDesc = exports.NameSection = exports.Section = void 0;
var Section;
(function (Section) {
    Section[Section["CUSTOM"] = 0] = "CUSTOM";
    Section[Section["TYPE"] = 1] = "TYPE";
    Section[Section["IMPORT"] = 2] = "IMPORT";
    Section[Section["FUNCTION"] = 3] = "FUNCTION";
    Section[Section["MEMORY"] = 5] = "MEMORY";
    Section[Section["EXPORT"] = 7] = "EXPORT";
    Section[Section["CODE"] = 10] = "CODE";
})(Section = exports.Section || (exports.Section = {}));
var NameSection;
(function (NameSection) {
    NameSection[NameSection["FUNCTION_NAME"] = 1] = "FUNCTION_NAME";
    NameSection[NameSection["LOCAL_NAME"] = 2] = "LOCAL_NAME";
})(NameSection = exports.NameSection || (exports.NameSection = {}));
var ImportDesc;
(function (ImportDesc) {
    ImportDesc[ImportDesc["MEMORY"] = 2] = "MEMORY";
})(ImportDesc = exports.ImportDesc || (exports.ImportDesc = {}));
var ExportDesc;
(function (ExportDesc) {
    ExportDesc[ExportDesc["FUNC"] = 0] = "FUNC";
})(ExportDesc = exports.ExportDesc || (exports.ExportDesc = {}));
exports["default"] = Section;
