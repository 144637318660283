"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var utils_1 = require("../utils");
exports["default"] = [
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(16, []),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray([16, 56, 108], __read((0, utils_1.same)(198, 3)), false), [254], false), __read((0, utils_1.same)(198, 3)), false), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray([], __read((0, utils_1.same)(10, 3)), false), __read((0, utils_1.same)(0, 5)), false), 2),
    (0, utils_1.pad)(5, __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(108, 2)), false), [254], false), __read((0, utils_1.same)(108, 2)), false), [254], false), __read((0, utils_1.same)(108, 2)), false), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray([4], __read((0, utils_1.mirr)([14, 21], [20, 14, 5])), false), [4], false), 1),
    (0, utils_1.pad)(6, [8, 20, 9, 2, 4, 8, 18, 5, 2], 1),
    (0, utils_1.pad)(6, [12, 18, 16, 8, 21, 18, 18, 13], 2),
    (0, utils_1.pad)(6, [4, 4], 8),
    (0, utils_1.pad)(6, (0, utils_1.mirr)([2, 4, 4, 8]), 2),
    (0, utils_1.pad)(6, (0, utils_1.mirr)([8, 4, 4, 2]), 2),
    (0, utils_1.pad)(6, (0, utils_1.mirr)([16, 84, 56]), 4),
    (0, utils_1.pad)(4, [24, 24, 24, 255, 255, 24, 24, 24], 4),
    (0, utils_1.pad)(12, [12, 12, 4, 8], 0),
    (0, utils_1.pad)(7, [126, 126], 7),
    (0, utils_1.pad)(11, [24, 24], 3),
    (0, utils_1.pad)(3, [
        6,
        6,
        12,
        12,
        24,
        24,
        48,
        48,
        96,
        96,
    ], 3),
    /* 0-9 */
    (0, utils_1.pad)(3, (0, utils_1.mirr)(__spreadArray([56, 108], __read((0, utils_1.same)(198, 2)), false), __spreadArray([], __read((0, utils_1.same)(214, 2)), false)), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray([24, 56, 120], __read((0, utils_1.same)(24, 6)), false), [126], false), 3),
    (0, utils_1.pad)(3, [
        124,
        198,
        6,
        12,
        24,
        48,
        96,
        192,
        198,
        254,
    ], 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)(__spreadArray([124, 198], __read((0, utils_1.same)(6, 2)), false), [60, 6]), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray([12, 28, 60, 108, 204, 254], __read((0, utils_1.same)(12, 3)), false), [30], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([254], __read((0, utils_1.same)(192, 3)), false), [252], false), __read((0, utils_1.same)(6, 3)), false), [198, 124], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([56, 96], __read((0, utils_1.same)(192, 2)), false), [252], false), __read((0, utils_1.same)(198, 4)), false), [124], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray([254, 198], __read((0, utils_1.same)(6, 2)), false), [12, 24], false), __read((0, utils_1.same)(48, 4)), false), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)(__spreadArray([124], __read((0, utils_1.same)(198, 3)), false), [124, 198]), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([124], __read((0, utils_1.same)(198, 4)), false), [126], false), __read((0, utils_1.same)(6, 2)), false), [12, 120], false), 3),
    (0, utils_1.pad)(6, [48, 48, 0, 0, 48, 48], 4),
    (0, utils_1.pad)(8, [12, 12, 0, 0, 12, 12, 4, 8], 0),
    (0, utils_1.pad)(8, __spreadArray([], __read((0, utils_1.mirr)([3, 12], [16])), false), 3),
    (0, utils_1.pad)(9, [31, 0, 31], 4),
    (0, utils_1.pad)(8, __spreadArray([], __read((0, utils_1.mirr)([24, 6], [1])), false), 3),
    (0, utils_1.pad)(6, [14, 17, 1, 2, 4, 4, 0, 4], 2),
    (0, utils_1.pad)(16, []),
    /* A-Z */
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray([16, 56, 108], __read((0, utils_1.same)(198, 2)), false), [254], false), __read((0, utils_1.same)(198, 4)), false), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)(__spreadArray([252], __read((0, utils_1.same)(102, 3)), false), [124, 102]), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)([60, 102, 194], (0, utils_1.same)(192, 4)), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)([248, 108, 102], (0, utils_1.same)(102, 4)), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)([254, 98, 96], [104, 120, 104, 96]), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray([254, 98, 96, 104, 120, 104], __read((0, utils_1.same)(96, 3)), false), [240], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        60,
        102,
        194
    ], __read((0, utils_1.same)(192, 1)), false), [
        206
    ], false), __read((0, utils_1.same)(198, 3)), false), [
        102,
        58,
    ], false), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)((0, utils_1.same)(198, 4), [254, 198]), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)([120], (0, utils_1.same)(48, 8)), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray([30], __read((0, utils_1.same)(12, 5)), false), __read((0, utils_1.same)(204, 3)), false), [120], false), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)(__spreadArray(__spreadArray([230], __read((0, utils_1.same)(102, 2)), false), [108, 120], false)), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray([240], __read((0, utils_1.same)(96, 7)), false), [98, 254], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray([198, 238], __read((0, utils_1.same)(254, 2)), false), [214], false), __read((0, utils_1.same)(198, 5)), false), 3),
    (0, utils_1.pad)(3, __spreadArray([198, 230, 246, 254, 222, 206], __read((0, utils_1.same)(198, 4)), false), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)([124], (0, utils_1.same)(198, 8)), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([252], __read((0, utils_1.same)(102, 3)), false), [124], false), __read((0, utils_1.same)(96, 4)), false), [240], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray([124], __read((0, utils_1.same)(198, 6)), false), [214, 222, 124, 12], false), 2),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([252], __read((0, utils_1.same)(102, 3)), false), [124, 108], false), __read((0, utils_1.same)(102, 3)), false), [230], false), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)(__spreadArray([124], __read((0, utils_1.same)(198, 2)), false), [96, 56, 12, 198]), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(126, 2)), false), [90], false), __read((0, utils_1.same)(24, 6)), false), [60], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray([], __read((0, utils_1.same)(198, 9)), false), [124], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray([], __read((0, utils_1.same)(198, 7)), false), [108, 56, 16], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(198, 4)), false), __read((0, utils_1.same)(214, 3)), false), [254], false), __read((0, utils_1.same)(238, 1)), false), [108], false), 3),
    (0, utils_1.pad)(3, (0, utils_1.mirr)(__spreadArray(__spreadArray([], __read((0, utils_1.same)(198, 2)), false), [108, 124, 56], false)), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(102, 4)), false), [60], false), __read((0, utils_1.same)(24, 4)), false), [60], false), 3),
    (0, utils_1.pad)(3, [
        254,
        134,
        6,
        12,
        24,
        48,
        96,
        192,
        194,
        254,
    ], 2),
    (0, utils_1.pad)(4, __spreadArray(__spreadArray([60], __read((0, utils_1.same)(48, 8)), false), [60], false), 2),
    (0, utils_1.pad)(3, [
        96,
        96,
        48,
        48,
        24,
        24,
        12,
        12,
        6,
        6,
    ], 3),
    (0, utils_1.pad)(4, __spreadArray(__spreadArray([120], __read((0, utils_1.same)(24, 8)), false), [120], false), 2),
    (0, utils_1.pad)(6, [4, 10, 17], 7),
    (0, utils_1.pad)(13, [254], 2),
    (0, utils_1.pad)(6, [8, 4], 8),
    /* a-z */
    (0, utils_1.pad)(7, __spreadArray(__spreadArray([120, 12, 124], __read((0, utils_1.same)(204, 3)), false), [118], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([224], __read((0, utils_1.same)(96, 2)), false), [120, 108], false), __read((0, utils_1.same)(102, 3)), false), [102, 124], false), 3),
    (0, utils_1.pad)(6, (0, utils_1.mirr)([124, 198], (0, utils_1.same)(192, 3)), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([28], __read((0, utils_1.same)(12, 2)), false), [60, 108], false), __read((0, utils_1.same)(204, 4)), false), [118], false), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray([124, 198, 254], __read((0, utils_1.same)(192, 2)), false), [198, 124], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([28, 54, 50], __read((0, utils_1.same)(48, 2)), false), [120], false), __read((0, utils_1.same)(48, 3)), false), [120], false), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray([118], __read((0, utils_1.same)(204, 4)), false), [124, 12, 204, 120], false), 1),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([224], __read((0, utils_1.same)(96, 2)), false), [108, 118], false), __read((0, utils_1.same)(102, 4)), false), [230], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(24, 2)), false), [0, 56], false), __read((0, utils_1.same)(24, 5)), false), [60], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(12, 2)), false), [0, 28], false), __read((0, utils_1.same)(12, 6)), false), __read((0, utils_1.same)(204, 2)), false), [120], false), 0),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        224
    ], __read((0, utils_1.same)(96, 2)), false), [
        102,
        108
    ], false), __read((0, utils_1.same)(120, 2)), false), [
        108,
        102,
        230,
    ], false), 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray([56], __read((0, utils_1.same)(24, 8)), false), [60], false), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray([236, 254], __read((0, utils_1.same)(214, 4)), false), [198], false), 3),
    (0, utils_1.pad)(6, __spreadArray([220], __read((0, utils_1.same)(102, 6)), false), 3),
    (0, utils_1.pad)(6, (0, utils_1.mirr)([124], (0, utils_1.same)(198, 5)), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray(__spreadArray(__spreadArray([220], __read((0, utils_1.same)(102, 4)), false), [124], false), __read((0, utils_1.same)(96, 2)), false), [240], false), 1),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray(__spreadArray(__spreadArray([118], __read((0, utils_1.same)(204, 4)), false), [124], false), __read((0, utils_1.same)(12, 2)), false), [30], false), 1),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray([220, 118, 102], __read((0, utils_1.same)(96, 3)), false), [240], false), 3),
    (0, utils_1.pad)(6, [124, 198, 96, 56, 12, 198, 124], 3),
    (0, utils_1.pad)(3, __spreadArray(__spreadArray(__spreadArray(__spreadArray([16], __read((0, utils_1.same)(48, 2)), false), [252], false), __read((0, utils_1.same)(48, 4)), false), [54, 28], false), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray([], __read((0, utils_1.same)(204, 6)), false), [118], false), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray([], __read((0, utils_1.same)(198, 5)), false), [108, 56], false), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(198, 2)), false), __read((0, utils_1.same)(214, 3)), false), [254, 108], false), 3),
    (0, utils_1.pad)(6, (0, utils_1.mirr)([198, 108], (0, utils_1.same)(56, 3)), 3),
    (0, utils_1.pad)(6, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(198, 6)), false), [126], false), __read((0, utils_1.same)(6, 2)), false), [124], false), 0),
    (0, utils_1.pad)(6, [254, 204, 24, 48, 96, 198, 254], 2),
    (0, utils_1.pad)(6, (0, utils_1.mirr)([3, 4, 4], [8, 4]), 2),
    (0, utils_1.pad)(6, (0, utils_1.same)(4, 8), 2),
    (0, utils_1.pad)(6, (0, utils_1.mirr)([24, 4, 4], [2, 4]), 2),
    (0, utils_1.pad)(16, []), // ~
].flat();
