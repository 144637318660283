"use strict";
exports.__esModule = true;
function createShader(gl, shaderSource, shaderType) {
    var shader = gl.createShader(shaderType);
    gl.shaderSource(shader, shaderSource);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        var info = gl.getShaderInfoLog(shader);
        console.error('Could not compile web gl shader', info);
    }
    return shader;
}
exports["default"] = createShader;
