"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateLookup = exports.Icon = void 0;
var font_1 = require("./font");
var types_1 = require("./types");
var types_2 = require("./fonts/types");
var offsetX = 0;
var offsetY = 150;
var icons = function (characterWidth, characterHeight, colors) { return [
    {
        commandsBeforeRenderingGlyphs: [
            [types_1.Command.FILL_COLOR, (colors === null || colors === void 0 ? void 0 : colors.inputConnectorBackground) || ''],
            [types_1.Command.RECTANGLE, 0, 0, characterWidth * 3, characterHeight],
            [types_1.Command.FILL_COLOR, (colors === null || colors === void 0 ? void 0 : colors.inputConnector) || ''],
        ],
        chars: [types_2.Glyph.CONNECTOR_LEFT, types_2.Glyph.SPACE, types_2.Glyph.CONNECTOR_RIGHT],
        colors: []
    },
    {
        commandsBeforeRenderingGlyphs: [
            [types_1.Command.FILL_COLOR, (colors === null || colors === void 0 ? void 0 : colors.switchBackground) || ''],
            [types_1.Command.RECTANGLE, 0, 0, characterWidth * 4, characterHeight],
            [types_1.Command.FILL_COLOR, (colors === null || colors === void 0 ? void 0 : colors.inputConnector) || ''],
        ],
        chars: [types_2.Glyph.CONNECTOR_LEFT, types_2.Glyph.SWITCH_KNOB, types_2.Glyph.SPACE, types_2.Glyph.CONNECTOR_RIGHT],
        colors: []
    },
    {
        commandsBeforeRenderingGlyphs: [
            [types_1.Command.FILL_COLOR, (colors === null || colors === void 0 ? void 0 : colors.switchBackground) || ''],
            [types_1.Command.RECTANGLE, 0, 0, characterWidth * 4, characterHeight],
            [types_1.Command.FILL_COLOR, (colors === null || colors === void 0 ? void 0 : colors.inputConnector) || ''],
        ],
        chars: [types_2.Glyph.CONNECTOR_LEFT, types_2.Glyph.SPACE, types_2.Glyph.SWITCH_KNOB, types_2.Glyph.CONNECTOR_RIGHT],
        colors: []
    },
    {
        commandsBeforeRenderingGlyphs: [],
        chars: [types_2.Glyph.ARROW_TOP],
        colors: [(colors === null || colors === void 0 ? void 0 : colors.arrow) || '']
    },
    {
        commandsBeforeRenderingGlyphs: [],
        chars: [types_2.Glyph.ARROW_RIGHT],
        colors: [(colors === null || colors === void 0 ? void 0 : colors.arrow) || '']
    },
    {
        commandsBeforeRenderingGlyphs: [],
        chars: [types_2.Glyph.ARROW_BOTTOM],
        colors: [(colors === null || colors === void 0 ? void 0 : colors.arrow) || '']
    },
    {
        commandsBeforeRenderingGlyphs: [],
        chars: [types_2.Glyph.ARROW_LEFT],
        colors: [(colors === null || colors === void 0 ? void 0 : colors.arrow) || '']
    },
]; };
var Icon;
(function (Icon) {
    Icon[Icon["INPUT"] = 0] = "INPUT";
    Icon[Icon["SWITCH_OFF"] = 1] = "SWITCH_OFF";
    Icon[Icon["SWITCH_ON"] = 2] = "SWITCH_ON";
    Icon[Icon["ARROW_TOP"] = 3] = "ARROW_TOP";
    Icon[Icon["ARROW_RIGHT"] = 4] = "ARROW_RIGHT";
    Icon[Icon["ARROW_BOTTOM"] = 5] = "ARROW_BOTTOM";
    Icon[Icon["ARROW_LEFT"] = 6] = "ARROW_LEFT";
})(Icon = exports.Icon || (exports.Icon = {}));
function generate(font, characterWidth, characterHeight, colors) {
    return __spreadArray([
        [types_1.Command.RESET_TRANSFORM],
        [types_1.Command.TRANSLATE, offsetX, offsetY]
    ], __read(icons(characterWidth, characterHeight, colors).flatMap(function (icon) {
        return __spreadArray(__spreadArray([], __read(icon.commandsBeforeRenderingGlyphs), false), __read(icon.chars.flatMap(function (char) {
            return __spreadArray(__spreadArray([], __read((0, font_1.drawCharacter)(font, char, characterWidth, characterHeight)), false), [
                [types_1.Command.TRANSLATE, characterWidth, 0],
            ], false);
        })), false);
    })), false);
}
exports["default"] = generate;
function generateIconPositions(characterWidth, characterHeight) {
    return icons(characterWidth, characterHeight).reduce(function (acc, current) {
        var length = acc.reduce(function (acc, icon) { return acc + icon[2]; }, 0);
        acc.push([offsetX + length, offsetY, current.chars.length * characterWidth]);
        return acc;
    }, []);
}
var generateLookup = function (characterWidth, characterHeight) {
    var iconPositions = generateIconPositions(characterWidth, characterHeight);
    return Object.fromEntries(icons(characterWidth, characterHeight).map(function (icon, index) {
        return [
            index,
            {
                x: iconPositions[index][0],
                y: iconPositions[index][1],
                spriteWidth: iconPositions[index][2],
                spriteHeight: characterHeight
            },
        ];
    }));
};
exports.generateLookup = generateLookup;
