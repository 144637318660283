"use strict";
exports.__esModule = true;
exports.fillBufferWithLineVertices = exports.fillBufferWithSpriteCoordinates = exports.fillBufferWithRectangleVertices = void 0;
/**
 * Fills a vertex buffer with vertices of the specified rectangle.
 * @param buffer
 * @param offset
 * @param x
 * @param y
 * @param width
 * @param height
 */
function fillBufferWithRectangleVertices(buffer, offset, x, y, width, height) {
    var x1 = x;
    var x2 = x + width;
    var y1 = y;
    var y2 = y + height;
    // Triangle 1 vertex 1
    buffer[offset] = x1;
    buffer[offset + 1] = y1;
    // vertex 2
    buffer[offset + 2] = x2;
    buffer[offset + 3] = y1;
    // vertex 3
    buffer[offset + 4] = x1;
    buffer[offset + 5] = y2;
    // Triangle 2 vertex 1
    buffer[offset + 6] = x1;
    buffer[offset + 7] = y2;
    // vertex 2
    buffer[offset + 8] = x2;
    buffer[offset + 9] = y1;
    // vertex 3
    buffer[offset + 10] = x2;
    buffer[offset + 11] = y2;
}
exports.fillBufferWithRectangleVertices = fillBufferWithRectangleVertices;
/**
 * Fills a texture coordinate buffer with the specified sprite coordinates.
 * @param buffer
 * @param offset
 * @param spriteX
 * @param spriteY
 * @param spriteWidth
 * @param spriteHeight
 * @param spriteSheetWidth
 * @param spriteSheetHeight
 */
function fillBufferWithSpriteCoordinates(buffer, offset, spriteX, spriteY, spriteWidth, spriteHeight, spriteSheetWidth, spriteSheetHeight) {
    var u1 = spriteX / spriteSheetWidth;
    var v2 = (spriteY + spriteHeight) / spriteSheetHeight;
    var u2 = (spriteX + spriteWidth) / spriteSheetWidth;
    var v1 = spriteY / spriteSheetHeight;
    buffer[offset + 0] = u1;
    buffer[offset + 1] = v1;
    buffer[offset + 2] = u2;
    buffer[offset + 3] = v1;
    buffer[offset + 4] = u1;
    buffer[offset + 5] = v2;
    buffer[offset + 6] = u1;
    buffer[offset + 7] = v2;
    buffer[offset + 8] = u2;
    buffer[offset + 9] = v1;
    buffer[offset + 10] = u2;
    buffer[offset + 11] = v2;
}
exports.fillBufferWithSpriteCoordinates = fillBufferWithSpriteCoordinates;
function fillBufferWithLineVertices(buffer, offset, x1, y1, x2, y2, thickness) {
    if (thickness === void 0) { thickness = 1; }
    /*      o (x1, y1)
     *      |\  Alpha
     *      | \
     *      |  \  <-- The hypotenuse here
     * legA |   \     is the line we want to render.
     *      |    \
     *      |     \ Beta
     *      '----- o (x2, y2)
     *        legB
     *
     *  We need to calculate one of it's angles to be able
     *  to copy and translate the original line to make it look thicker.
     */
    var legA = y1 - y2;
    var legB = x1 - x2;
    var alpha = Math.atan(legA / legB);
    /*               |
     *               |--- o (xA, yA)
     *               |   /|\ Alpha
     *               |  / | \
     *         Beta  | /  |  \
     *      (x1, y1) |/   |   \
     * --------------*-)--+----\---->
     *              /|\         \
     *             / | \         \
     *            /  |  \         \
     *           /   |   \         '
     * (xD, xD) o-)--+    \          .
     *           \   |     \
     *            \  |      '    <-- This is the central line
     *             \          .      for which we have the cooridnates
     *              '
     *                .  <-- This is a slightly translated line
     */
    thickness = thickness / 2;
    var translateX = Math.max(1, thickness) * Math.sin(alpha);
    var translateY = Math.max(1, thickness) * Math.cos(alpha);
    var xA = x1;
    var yA = y1;
    var xD = x1 + translateX;
    var yD = y1 - translateY;
    var xB = x2;
    var yB = y2;
    var xC = x2 + translateX;
    var yC = y2 - translateY;
    if (thickness >= 1) {
        xA = x1 - translateX;
        yA = y1 + translateY;
        xB = x2 - translateX;
        yB = y2 + translateY;
    }
    // triangle 1 vertex 1
    buffer[offset] = xA;
    buffer[offset + 1] = yA;
    // vertex 2
    buffer[offset + 2] = xB;
    buffer[offset + 3] = yB;
    // vertex 3
    buffer[offset + 4] = xC;
    buffer[offset + 5] = yC;
    // triangle 2 vertex 1
    buffer[offset + 6] = xD;
    buffer[offset + 7] = yD;
    // vertex 2
    buffer[offset + 8] = xA;
    buffer[offset + 9] = yA;
    // vertex 3
    buffer[offset + 10] = xC;
    buffer[offset + 11] = yC;
}
exports.fillBufferWithLineVertices = fillBufferWithLineVertices;
