"use strict";
exports.__esModule = true;
var types_1 = require("../types");
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var initBlock = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    context.blockStack.push({
        expectedResultIsInteger: false,
        hasExpectedResult: false,
        blockType: types_1.BLOCK_TYPE.INIT
    });
    return context;
};
exports["default"] = initBlock;
