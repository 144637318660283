"use strict";
exports.__esModule = true;
exports.BLOCK_TYPE = exports.MemoryTypes = void 0;
var MemoryTypes;
(function (MemoryTypes) {
    MemoryTypes[MemoryTypes["int"] = 0] = "int";
    MemoryTypes[MemoryTypes["int*"] = 1] = "int*";
    MemoryTypes[MemoryTypes["int**"] = 2] = "int**";
    MemoryTypes[MemoryTypes["float"] = 3] = "float";
    MemoryTypes[MemoryTypes["float*"] = 4] = "float*";
    MemoryTypes[MemoryTypes["float**"] = 5] = "float**";
})(MemoryTypes = exports.MemoryTypes || (exports.MemoryTypes = {}));
var BLOCK_TYPE;
(function (BLOCK_TYPE) {
    BLOCK_TYPE[BLOCK_TYPE["MODULE"] = 0] = "MODULE";
    BLOCK_TYPE[BLOCK_TYPE["LOOP"] = 1] = "LOOP";
    BLOCK_TYPE[BLOCK_TYPE["CONDITION"] = 2] = "CONDITION";
    BLOCK_TYPE[BLOCK_TYPE["FUNCTION"] = 3] = "FUNCTION";
    BLOCK_TYPE[BLOCK_TYPE["BLOCK"] = 4] = "BLOCK";
    BLOCK_TYPE[BLOCK_TYPE["INIT"] = 5] = "INIT";
})(BLOCK_TYPE = exports.BLOCK_TYPE || (exports.BLOCK_TYPE = {}));
