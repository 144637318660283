"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateLookup = exports.Icon = void 0;
var types_1 = require("./types");
var offsetX = 0;
var offsetY = 180;
var fillColors = [
    'menuItemBackground',
    'menuItemBackgroundHighlighted',
    'background',
    'backgroundDots',
    'moduleBackground',
    'moduleBackgroundDragged',
    'wire',
    'wireHighlighted',
    'errorMessageBackground',
    'dialogBackground',
    'dialogDimmer',
    'highlightedCodeLine',
];
var Icon;
(function (Icon) {
    Icon[Icon["INPUT"] = 0] = "INPUT";
    Icon[Icon["SWITCH_OFF"] = 1] = "SWITCH_OFF";
    Icon[Icon["SWITCH_ON"] = 2] = "SWITCH_ON";
})(Icon = exports.Icon || (exports.Icon = {}));
function generate(characterWidth, characterHight, colors) {
    return __spreadArray([
        [types_1.Command.RESET_TRANSFORM],
        [types_1.Command.TRANSLATE, offsetX, offsetY]
    ], __read(fillColors.flatMap(function (color) {
        return [
            [types_1.Command.FILL_COLOR, colors[color]],
            [types_1.Command.RECTANGLE, 0, 0, characterWidth, characterHight],
            [types_1.Command.TRANSLATE, characterWidth, 0],
        ];
    })), false);
}
exports["default"] = generate;
var generateLookup = function (characterWidth, characterHeight) {
    return Object.fromEntries(fillColors.map(function (color) { return [
        color,
        {
            x: offsetX + fillColors.indexOf(color) * characterWidth,
            y: offsetY,
            spriteWidth: characterWidth,
            spriteHeight: characterHeight
        },
    ]; }));
};
exports.generateLookup = generateLookup;
