"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var compiler_1 = require("../compiler");
var pow2 = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand = context.stack.pop();
    if (!operand) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    if (!operand.isInteger) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_INTEGER_OPERAND, line, context);
    }
    context.stack.push({ isInteger: true, isNonZero: false });
    return (0, compiler_1.compileSegment)(['push 2', 'push 1', 'sub', 'swap', 'shiftLeft'], context);
};
exports["default"] = pow2;
