"use strict";
exports.__esModule = true;
function createProgram(gl, shaders) {
    var program = gl.createProgram();
    shaders.forEach(function (shader) {
        gl.attachShader(program, shader);
    });
    gl.linkProgram(program);
    var linked = gl.getProgramParameter(program, gl.LINK_STATUS);
    if (!linked) {
        var lastError = gl.getProgramInfoLog(program);
        console.error('Error in program linking: ' + lastError);
        gl.deleteProgram(program);
        return null;
    }
    return program;
}
exports["default"] = createProgram;
