"use strict";
exports.__esModule = true;
exports.Glyph = void 0;
var Glyph;
(function (Glyph) {
    Glyph[Glyph["SPACE"] = 0] = "SPACE";
    Glyph[Glyph["DOT"] = 1] = "DOT";
    Glyph[Glyph["ARROW_TOP"] = 2] = "ARROW_TOP";
    Glyph[Glyph["ARROW_RIGHT"] = 3] = "ARROW_RIGHT";
    Glyph[Glyph["ARROW_BOTTOM"] = 4] = "ARROW_BOTTOM";
    Glyph[Glyph["ARROW_LEFT"] = 5] = "ARROW_LEFT";
    Glyph[Glyph["CONNECTOR_LEFT"] = 6] = "CONNECTOR_LEFT";
    Glyph[Glyph["CONNECTOR_RIGHT"] = 7] = "CONNECTOR_RIGHT";
    Glyph[Glyph["SWITCH_KNOB"] = 8] = "SWITCH_KNOB";
    Glyph[Glyph["FILL"] = 9] = "FILL";
    Glyph[Glyph["SEMI_FILL"] = 10] = "SEMI_FILL";
    Glyph[Glyph["DITHER_1"] = 11] = "DITHER_1";
    Glyph[Glyph["DITHER_2"] = 12] = "DITHER_2";
    Glyph[Glyph["DITHER_3"] = 13] = "DITHER_3";
    Glyph[Glyph["DITHER_4"] = 14] = "DITHER_4";
    Glyph[Glyph["DITHER_5"] = 15] = "DITHER_5";
    Glyph[Glyph["THICK_LINE_LEFT"] = 16] = "THICK_LINE_LEFT";
    Glyph[Glyph["THICK_LINE_RIGHT"] = 17] = "THICK_LINE_RIGHT";
    Glyph[Glyph["SLIM_LINE_LEFT"] = 18] = "SLIM_LINE_LEFT";
    Glyph[Glyph["SLIM_LINE_RIGHT"] = 19] = "SLIM_LINE_RIGHT";
    Glyph[Glyph["SLASH"] = 20] = "SLASH";
})(Glyph = exports.Glyph || (exports.Glyph = {}));
