"use strict";
exports.__esModule = true;
/**
 * WebAssembly instuction set
 */
var WASMInstruction;
(function (WASMInstruction) {
    /** Unreachable */
    WASMInstruction[WASMInstruction["UNREACHABLE"] = 0] = "UNREACHABLE";
    /** No-Op. It does nothing. */
    WASMInstruction[WASMInstruction["NOP"] = 1] = "NOP";
    /** Pushes an entry onto the control-flow stack. */
    WASMInstruction[WASMInstruction["BLOCK"] = 2] = "BLOCK";
    /** Binds a label to the current position, and pushes an entry onto the control-flow stack. */
    WASMInstruction[WASMInstruction["LOOP"] = 3] = "LOOP";
    /** If */
    WASMInstruction[WASMInstruction["IF"] = 4] = "IF";
    /** Else */
    WASMInstruction[WASMInstruction["ELSE"] = 5] = "ELSE";
    /** Pops an entry from the control-flow stack. */
    WASMInstruction[WASMInstruction["END"] = 11] = "END";
    /** Unconditional branch */
    WASMInstruction[WASMInstruction["BR"] = 12] = "BR";
    /** Conditional branch */
    WASMInstruction[WASMInstruction["BR_IF"] = 13] = "BR_IF";
    /** Table branch */
    WASMInstruction[WASMInstruction["BR_TABLE"] = 14] = "BR_TABLE";
    /** Return */
    WASMInstruction[WASMInstruction["RETURN"] = 15] = "RETURN";
    WASMInstruction[WASMInstruction["CALL"] = 16] = "CALL";
    /** Drop */
    WASMInstruction[WASMInstruction["DROP"] = 26] = "DROP";
    /** Get Local */
    WASMInstruction[WASMInstruction["LOCAL_GET"] = 32] = "LOCAL_GET";
    /** Set Local */
    WASMInstruction[WASMInstruction["LOCAL_SET"] = 33] = "LOCAL_SET";
    WASMInstruction[WASMInstruction["I32_LOAD"] = 40] = "I32_LOAD";
    WASMInstruction[WASMInstruction["F32_LOAD"] = 42] = "F32_LOAD";
    WASMInstruction[WASMInstruction["I32_LOAD_8_S"] = 44] = "I32_LOAD_8_S";
    WASMInstruction[WASMInstruction["I32_LOAD_8_U"] = 45] = "I32_LOAD_8_U";
    WASMInstruction[WASMInstruction["I32_LOAD_16_S"] = 46] = "I32_LOAD_16_S";
    WASMInstruction[WASMInstruction["I32_LOAD_16_U"] = 47] = "I32_LOAD_16_U";
    WASMInstruction[WASMInstruction["I32_STORE"] = 54] = "I32_STORE";
    WASMInstruction[WASMInstruction["F32_STORE"] = 56] = "F32_STORE";
    /** varsint32 constant */
    WASMInstruction[WASMInstruction["I32_CONST"] = 65] = "I32_CONST";
    /** varsint64 constant */
    WASMInstruction[WASMInstruction["I64_CONTS"] = 66] = "I64_CONTS";
    /** float32 constant */
    WASMInstruction[WASMInstruction["F32_CONST"] = 67] = "F32_CONST";
    /** float64 constant */
    WASMInstruction[WASMInstruction["F64_CONST"] = 68] = "F64_CONST";
    /**
     * Equal with zero.
     * The eqz instruction returns true if the operand is equal to zero, or
     * false otherwise.
     * Type signature: (param i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_EQZ"] = 69] = "I32_EQZ";
    /**
     * Equality.
     * Tests whether the operands are equal.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_EQ"] = 70] = "I32_EQ";
    /**
     * Inequality.
     * Tests whether the operands are not equal.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_NE"] = 71] = "I32_NE";
    /**
     * Less than (signed).
     * Tests whether the first operand is less than the second operand.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_LT_S"] = 72] = "I32_LT_S";
    /**
     * Less than (unsigned).
     * Tests whether the first operand is less than the second operand.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_LT_U"] = 73] = "I32_LT_U";
    WASMInstruction[WASMInstruction["I32_GT_S"] = 74] = "I32_GT_S";
    WASMInstruction[WASMInstruction["I32_GT_U"] = 75] = "I32_GT_U";
    /**
     * Less than or equal to (signed).
     * Tests whether the first operand is less than or equal to the second operand.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_LE_S"] = 76] = "I32_LE_S";
    /**
     * Less than or equal to (unsigned).
     * Tests whether the first operand is less than or equal to the second operand.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_LE_U"] = 77] = "I32_LE_U";
    /** Greater than or equal to (signed)
     *
     *
     */
    WASMInstruction[WASMInstruction["I32_GE_S"] = 78] = "I32_GE_S";
    /** Integer Greater Than or Equal To, Unsigned */
    WASMInstruction[WASMInstruction["I32_GE_U"] = 79] = "I32_GE_U";
    /**
     * Floating-Point Equality
     * Type signature: (param f32, f32) (result i32)
     */
    WASMInstruction[WASMInstruction["F32_EQ"] = 91] = "F32_EQ";
    /**
     * Floating-Point Less Than
     * Type signature: (param f32, f32) (result i32)
     */
    WASMInstruction[WASMInstruction["F32_LT"] = 93] = "F32_LT";
    /**
     * Floating-Point Greater Than
     * Type signature: (param f32, f32) (result i32)
     */
    WASMInstruction[WASMInstruction["F32_GT"] = 94] = "F32_GT";
    /**
     * Floating-Point Less Than Or Equal To
     * Type signature (param f32, f32) (result i32)
     */
    WASMInstruction[WASMInstruction["F32_LE"] = 95] = "F32_LE";
    /**
     * Floating-Point Greater Than Or Equal To
     * Type signature: (param f32, f32) (result i32)
     */
    WASMInstruction[WASMInstruction["F32_GE"] = 96] = "F32_GE";
    /**
     * Aadd.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_ADD"] = 106] = "I32_ADD";
    /**
     * Substract.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_SUB"] = 107] = "I32_SUB";
    /**
     * Multiply.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_MUL"] = 108] = "I32_MUL";
    /**
     * Divide (signed).
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_DIV_S"] = 109] = "I32_DIV_S";
    /**
     * Divide (unsigned).
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_DIV_U"] = 110] = "I32_DIV_U";
    /**
     * Remainder.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_REM_S"] = 111] = "I32_REM_S";
    /**
     * Bitwise and.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_AND"] = 113] = "I32_AND";
    /**
     * Bitwise or.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_OR"] = 114] = "I32_OR";
    /**
     * Exclusive or.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_XOR"] = 115] = "I32_XOR";
    /**
     * Shift left.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_SHL"] = 116] = "I32_SHL";
    /**
     * Shift right (signed).
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_SHR_S"] = 117] = "I32_SHR_S";
    /**
     * Shift right (unsigned).
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_SHR_U"] = 118] = "I32_SHR_U";
    /**
     * Rotate left.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_ROTL"] = 119] = "I32_ROTL";
    /**
     * Rotate right.
     * Type signature: (param i32 i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_ROTR"] = 120] = "I32_ROTR";
    /**
     * Count leading zeros.
     * It returns the number of leading zeros in its operand.
     * Type signature: (param i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_CLZ"] = 103] = "I32_CLZ";
    /**
     * Count trailing zeros.
     * It returns the number of trailing zeros in its operand.
     * Type signature: (param i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_CTZ"] = 104] = "I32_CTZ";
    /**
     * Population count.
     * It returns the number of 1-bits in its operand.
     * Type signature: (param i32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_POPCNT"] = 105] = "I32_POPCNT";
    WASMInstruction[WASMInstruction["F32_ABS"] = 139] = "F32_ABS";
    WASMInstruction[WASMInstruction["F32_ADD"] = 146] = "F32_ADD";
    WASMInstruction[WASMInstruction["F32_SUB"] = 147] = "F32_SUB";
    WASMInstruction[WASMInstruction["F32_MUL"] = 148] = "F32_MUL";
    WASMInstruction[WASMInstruction["F32_DIV"] = 149] = "F32_DIV";
    WASMInstruction[WASMInstruction["F32_NEAREST"] = 144] = "F32_NEAREST";
    WASMInstruction[WASMInstruction["F32_SQRT"] = 145] = "F32_SQRT";
    /**
     * Truncate Floating-Point to Integer, Signed
     * Type signature: (param f32) (result i32)
     */
    WASMInstruction[WASMInstruction["I32_TUNC_F32_S"] = 168] = "I32_TUNC_F32_S";
    /**
     * Convert Integer To Floating-Point, Signed
     *  Type signature: (param f32) (result f32)
     */
    WASMInstruction[WASMInstruction["F32_CONVERT_I32_S"] = 178] = "F32_CONVERT_I32_S";
})(WASMInstruction || (WASMInstruction = {}));
exports["default"] = WASMInstruction;
