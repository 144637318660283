"use strict";
exports.__esModule = true;
exports.GLOBAL_ALIGNMENT_BOUNDARY = exports.LOGIC_LOW = exports.LOGIC_HIGH = exports.I16_SIGNED_SMALLEST_NUMBER = exports.I16_SIGNED_LARGEST_NUMBER = exports.I32_SIGNED_LARGEST_NUMBER = exports.I32_SIGNED_SMALLEST_NUMBER = void 0;
exports.I32_SIGNED_SMALLEST_NUMBER = -2147483648;
exports.I32_SIGNED_LARGEST_NUMBER = 2147483647;
exports.I16_SIGNED_LARGEST_NUMBER = 32767;
exports.I16_SIGNED_SMALLEST_NUMBER = -32768;
exports.LOGIC_HIGH = exports.I16_SIGNED_LARGEST_NUMBER;
exports.LOGIC_LOW = 0;
exports.GLOBAL_ALIGNMENT_BOUNDARY = 4;
