"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var instructionHelpers_1 = require("../wasmUtils/instructionHelpers");
var _localGet = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type === "identifier" /* ArgumentType.IDENTIFIER */) {
        var local = context.namespace.locals.get(line.arguments[0].value);
        if (!local) {
            throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
        }
        context.stack.push({ isInteger: local.isInteger, isNonZero: false });
        return (0, utils_1.saveByteCode)(context, (0, instructionHelpers_1.localGet)(local.index));
    }
    else {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
};
exports["default"] = _localGet;
