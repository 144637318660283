"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var compiler_1 = require("../compiler");
var dup = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand = context.stack.pop();
    if (!operand) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    var tempName = '__dupTemp' + line.lineNumber;
    context.stack.push(operand);
    return (0, compiler_1.compileSegment)([
        "local ".concat(operand.isInteger ? 'int' : 'float', " ").concat(tempName),
        "localSet ".concat(tempName),
        "localGet ".concat(tempName),
        "localGet ".concat(tempName),
    ], context);
};
exports["default"] = dup;
