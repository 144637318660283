"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateLookup = void 0;
var types_1 = require("./types");
var offsetX = 600;
var offsetY = 300;
function generate(characterWidth, characterHeight, colors) {
    var values = new Array(characterHeight * 8).fill(0).map(function (item, index) { return index; });
    return __spreadArray([
        [types_1.Command.RESET_TRANSFORM],
        [types_1.Command.TRANSLATE, offsetX, offsetY],
        [types_1.Command.FILL_COLOR, colors.plotterBackground],
        [types_1.Command.RECTANGLE, 0, 0, 200, 200],
        [types_1.Command.FILL_COLOR, colors.plotterTrace]
    ], __read(values.map(function (value) {
        return [types_1.Command.RECTANGLE, value, characterHeight * 8 - value, 1, 1];
    })), false);
}
exports["default"] = generate;
var generateLookup = function (characterWidth, characterHeight) {
    var values = new Array(characterHeight * 8).fill(0).map(function (item, index) { return index; });
    return Object.fromEntries(values.map(function (value) { return [
        value,
        {
            x: offsetX + value,
            y: offsetY,
            spriteWidth: 1,
            spriteHeight: characterHeight * 8
        },
    ]; }));
};
exports.generateLookup = generateLookup;
