"use strict";
exports.__esModule = true;
var types_1 = require("../types");
var errors_1 = require("../errors");
var _module = function (line, context) {
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type === "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    context.blockStack.push({
        hasExpectedResult: false,
        expectedResultIsInteger: false,
        blockType: types_1.BLOCK_TYPE.MODULE
    });
    context.namespace.moduleName = line.arguments[0].value;
    return context;
};
exports["default"] = _module;
