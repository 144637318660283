"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var utils_1 = require("../utils");
exports["default"] = [
    (0, utils_1.pad)(16, []),
    __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(0, 7)), false), [24, 24], false), __read((0, utils_1.same)(0, 7)), false),
    __spreadArray([24, 24, 36, 36, 66, 66], __read((0, utils_1.same)(0, 10)), false),
    (0, utils_1.pad)(8, __spreadArray([], __read((0, utils_1.mirr)([24, 6], [1])), false), 3),
    __spreadArray(__spreadArray([], __read((0, utils_1.same)(0, 10)), false), [66, 66, 36, 36, 24, 24], false),
    (0, utils_1.pad)(8, __spreadArray([], __read((0, utils_1.mirr)([3, 12], [16])), false), 3),
    (0, utils_1.pad)(4, __spreadArray(__spreadArray([60], __read((0, utils_1.same)(48, 8)), false), [60], false), 2),
    (0, utils_1.pad)(4, __spreadArray(__spreadArray([120], __read((0, utils_1.same)(24, 8)), false), [120], false), 2),
    (0, utils_1.pad)(5, __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(108, 2)), false), [254], false), __read((0, utils_1.same)(108, 2)), false), [254], false), __read((0, utils_1.same)(108, 2)), false), 3),
    (0, utils_1.same)(255, 16),
    (0, utils_1.same)([170, 85], 8),
    __spreadArray([], __read((0, utils_1.same)([170, 68, 170, 17], 4)), false),
    __spreadArray([], __read((0, utils_1.same)([170, 68, 170, 0], 4)), false),
    __spreadArray([], __read((0, utils_1.same)([170, 0], 8)), false),
    __spreadArray([], __read((0, utils_1.same)([34, 0, 170, 0], 4)), false),
    __spreadArray([], __read((0, utils_1.same)([34, 0, 136, 0], 4)), false),
    (0, utils_1.same)(240, 16),
    (0, utils_1.same)(15, 16),
    (0, utils_1.same)(192, 16),
    (0, utils_1.same)(3, 16),
    (0, utils_1.invert)((0, utils_1.pad)(3, [
        6,
        6,
        12,
        12,
        24,
        24,
        48,
        48,
        96,
        96,
    ], 3)), // SLASH
].flat();
