"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var wasm = function (line, context) {
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type !== "literal" /* ArgumentType.LITERAL */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_VALUE, line, context);
    }
    return (0, utils_1.saveByteCode)(context, [line.arguments[0].value]);
};
exports["default"] = wasm;
