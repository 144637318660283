"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var instructionHelpers_1 = require("../wasmUtils/instructionHelpers");
var compiler_1 = require("../compiler");
var instructionToByteCodeMap = {
    load: (0, instructionHelpers_1.i32load)(),
    load8s: (0, instructionHelpers_1.i32load8s)(),
    load8u: (0, instructionHelpers_1.i32load8u)(),
    load16s: (0, instructionHelpers_1.i32load16s)(),
    load16u: (0, instructionHelpers_1.i32load16u)()
};
var load = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var operand = context.stack.pop();
    if (!operand) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSUFFICIENT_OPERANDS, line, context);
    }
    if ((0, utils_1.areAllOperandsIntegers)(operand)) {
        if (operand.isSafeMemoryAddress) {
            context.stack.push({ isInteger: true, isNonZero: false });
            return (0, utils_1.saveByteCode)(context, instructionToByteCodeMap[line.instruction]);
        }
        else {
            context.stack.push({ isInteger: true, isNonZero: false });
            var tempVariableName = '__loadAddress_temp_' + line.lineNumber;
            // Memory overflow protection.
            return (0, compiler_1.compileSegment)(__spreadArray([
                "local int ".concat(tempVariableName),
                "localSet ".concat(tempVariableName),
                "localGet ".concat(tempVariableName),
                "push ".concat(context.memoryByteSize - 1),
                'greaterThan',
                'if int',
                "push 0",
                'else',
                "localGet ".concat(tempVariableName),
                'ifEnd'
            ], __read(instructionToByteCodeMap[line.instruction].map(function (wasmInstruction) {
                return "wasm ".concat(wasmInstruction);
            })), false), context);
        }
    }
    else {
        throw (0, errors_1.getError)(errors_1.ErrorCode.ONLY_INTEGERS, line, context);
    }
};
exports["default"] = load;
