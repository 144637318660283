"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var utils_1 = require("../utils");
exports["default"] = [
    (0, utils_1.pad)(10, []),
    __spreadArray(__spreadArray(__spreadArray([], __read((0, utils_1.same)(0, 4)), false), [24, 24], false), __read((0, utils_1.same)(0, 4)), false),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(10, []),
    (0, utils_1.pad)(0, [1, 1, 2, 2, 4, 4, 8, 8], 2), // SLASH
].flat();
