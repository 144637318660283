"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.invert = exports.mirr = exports.same = exports.pad = void 0;
function pad(paddingLeft, arr, paddingRight) {
    if (paddingRight === void 0) { paddingRight = 0; }
    return __spreadArray(__spreadArray(__spreadArray([], __read(new Array(paddingLeft).fill(0)), false), __read(arr), false), __read(new Array(paddingRight).fill(0)), false);
}
exports.pad = pad;
function same(byte, times) {
    return new Array(times).fill(byte).flat();
}
exports.same = same;
function mirr(toBeMirrored, middle) {
    if (middle === void 0) { middle = []; }
    return __spreadArray(__spreadArray(__spreadArray([], __read(toBeMirrored), false), __read(middle), false), __read(toBeMirrored.slice().reverse()), false);
}
exports.mirr = mirr;
function invert(bytes) {
    return bytes.map(function (byte) { return ~byte; });
}
exports.invert = invert;
