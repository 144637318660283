"use strict";
exports.__esModule = true;
exports.sortModules = void 0;
function sortModules(modules) {
    return modules
        .sort(function (astA, astB) {
        var _a, _b;
        var moduleIdA = ((_a = astA.find(function (_a) {
            var instruction = _a.instruction;
            return instruction === 'module';
        })) === null || _a === void 0 ? void 0 : _a.arguments[0].value) || '';
        var moduleIdB = ((_b = astB.find(function (_a) {
            var instruction = _a.instruction;
            return instruction === 'module';
        })) === null || _b === void 0 ? void 0 : _b.arguments[0].value) || '';
        if (moduleIdA < moduleIdB) {
            return -1;
        }
        if (moduleIdA > moduleIdB) {
            return 1;
        }
        return 0;
    })
        .sort(function (astA, astB) {
        var _a, _b;
        var moduleIdA = ((_a = astA.find(function (_a) {
            var instruction = _a.instruction;
            return instruction === 'module';
        })) === null || _a === void 0 ? void 0 : _a.arguments[0].value) || '';
        var moduleIdB = ((_b = astB.find(function (_a) {
            var instruction = _a.instruction;
            return instruction === 'module';
        })) === null || _b === void 0 ? void 0 : _b.arguments[0].value) || '';
        var intermodulerConnectionsA = astA
            .filter(function (_a) {
            var instruction = _a.instruction, _arguments = _a.arguments;
            return (['int*', 'int**', 'float*', 'float**', 'init', 'int'].includes(instruction) &&
                _arguments[0] &&
                _arguments[1] &&
                _arguments[0].type === "identifier" /* ArgumentType.IDENTIFIER */ &&
                _arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */ &&
                /&(\S+)\.(\S+)/.test(_arguments[1].value));
        })
            .map(function (_a) {
            var _arguments = _a.arguments;
            var value = _arguments[1].value;
            return value.split('.')[0].substring(1);
        });
        var intermodulerConnectionsB = astB
            .filter(function (_a) {
            var instruction = _a.instruction, _arguments = _a.arguments;
            return (['int*', 'int**', 'float*', 'float**', 'init', 'int'].includes(instruction) &&
                _arguments[0] &&
                _arguments[1] &&
                _arguments[0].type === "identifier" /* ArgumentType.IDENTIFIER */ &&
                _arguments[1].type === "identifier" /* ArgumentType.IDENTIFIER */ &&
                /&(\S+)\.(\S+)/.test(_arguments[1].value));
        })
            .map(function (_a) {
            var _arguments = _a.arguments;
            var value = _arguments[1].value;
            return value.split('.')[0].substring(1);
        });
        if (intermodulerConnectionsB.includes(moduleIdA) && !intermodulerConnectionsA.includes(moduleIdB)) {
            return 1;
        }
        else if (!intermodulerConnectionsB.includes(moduleIdA) && intermodulerConnectionsA.includes(moduleIdB)) {
            return -1;
        }
        else {
            return 0;
        }
    });
}
exports.sortModules = sortModules;
