"use strict";
exports.__esModule = true;
var errors_1 = require("../errors");
var utils_1 = require("../utils");
var local = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    if (!line.arguments[0] || !line.arguments[1]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    if (line.arguments[0].type !== "identifier" /* ArgumentType.IDENTIFIER */ || line.arguments[1].type !== "identifier" /* ArgumentType.IDENTIFIER */) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.EXPECTED_IDENTIFIER, line, context);
    }
    context.namespace.locals.set(line.arguments[1].value, {
        isInteger: line.arguments[0].value === 'int',
        index: context.namespace.locals.size
    });
    return context;
};
exports["default"] = local;
