"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
var errors_1 = require("../errors");
var instructionHelpers_1 = require("../wasmUtils/instructionHelpers");
var utils_1 = require("../utils");
function getTypeAppropriateConstInstruction(argument) {
    if (argument.isInteger) {
        return (0, instructionHelpers_1.i32const)(argument.value);
    }
    else {
        return (0, instructionHelpers_1.f32const)(argument.value);
    }
}
var push = function (line, context) {
    if (!(0, utils_1.isInstructionIsInsideAModule)(context.blockStack)) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.INSTRUCTION_INVALID_OUTSIDE_BLOCK, line, context);
    }
    var _a = context.namespace, locals = _a.locals, memory = _a.memory, consts = _a.consts;
    if (!line.arguments[0]) {
        throw (0, errors_1.getError)(errors_1.ErrorCode.MISSING_ARGUMENT, line, context);
    }
    var argument = line.arguments[0];
    if (argument.type === "identifier" /* ArgumentType.IDENTIFIER */) {
        if ((0, utils_1.isMemoryIdentifier)(memory, argument.value)) {
            var memoryItem = (0, utils_1.getDataStructure)(memory, argument.value);
            if (!memoryItem) {
                throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
            }
            context.stack.push({ isInteger: memoryItem.isInteger, isNonZero: false });
            return (0, utils_1.saveByteCode)(context, __spreadArray(__spreadArray([], __read((0, instructionHelpers_1.i32const)(memoryItem.byteAddress)), false), __read((memoryItem.isInteger ? (0, instructionHelpers_1.i32load)() : (0, instructionHelpers_1.f32load)())), false));
        }
        else if ((0, utils_1.isMemoryPointerIdentifier)(memory, argument.value)) {
            var memoryItem = (0, utils_1.getDataStructure)(memory, argument.value.substring(1));
            if (!memoryItem) {
                throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
            }
            context.stack.push({ isInteger: memoryItem.isPointingToInteger, isNonZero: false });
            return (0, utils_1.saveByteCode)(context, __spreadArray(__spreadArray(__spreadArray([], __read((0, instructionHelpers_1.i32const)(memoryItem.byteAddress)), false), __read((memoryItem.isPointingToPointer ? __spreadArray(__spreadArray([], __read((0, instructionHelpers_1.i32load)()), false), __read((0, instructionHelpers_1.i32load)()), false) : (0, instructionHelpers_1.i32load)())), false), __read((memoryItem.isPointingToInteger ? (0, instructionHelpers_1.i32load)() : (0, instructionHelpers_1.f32load)())), false));
        }
        else if ((0, utils_1.isMemoryReferenceIdentifier)(memory, argument.value)) {
            var value = 0;
            if (argument.value.startsWith('&')) {
                value = (0, utils_1.getDataStructureByteAddress)(memory, argument.value.substring(1));
            }
            else {
                value = (0, utils_1.getMemoryStringLastByteAddress)(memory, argument.value.slice(0, -1));
            }
            context.stack.push({ isInteger: true, isNonZero: value !== 0, isSafeMemoryAddress: true });
            return (0, utils_1.saveByteCode)(context, (0, instructionHelpers_1.i32const)(value));
        }
        else if ((0, utils_1.isElementCountIdentifier)(memory, argument.value)) {
            context.stack.push({ isInteger: true, isNonZero: true });
            return (0, utils_1.saveByteCode)(context, (0, instructionHelpers_1.i32const)((0, utils_1.getElementCount)(memory, argument.value.substring(1))));
        }
        else if ((0, utils_1.isElementWordSizeIdentifier)(memory, argument.value)) {
            context.stack.push({ isInteger: true, isNonZero: true });
            return (0, utils_1.saveByteCode)(context, (0, instructionHelpers_1.i32const)((0, utils_1.getElementWordSize)(memory, argument.value.substring(1))));
        }
        else if (typeof consts[argument.value] !== 'undefined') {
            context.stack.push({
                isInteger: consts[argument.value].isInteger,
                isNonZero: consts[argument.value].value !== 0
            });
            return (0, utils_1.saveByteCode)(context, consts[argument.value].isInteger
                ? (0, instructionHelpers_1.i32const)(consts[argument.value].value)
                : (0, instructionHelpers_1.f32const)(consts[argument.value].value));
        }
        else {
            var local = locals.get(argument.value);
            if (!local) {
                throw (0, errors_1.getError)(errors_1.ErrorCode.UNDECLARED_IDENTIFIER, line, context);
            }
            context.stack.push({ isInteger: local.isInteger, isNonZero: false });
            return (0, utils_1.saveByteCode)(context, (0, instructionHelpers_1.localGet)(local.index));
        }
    }
    else {
        context.stack.push({ isInteger: argument.isInteger, isNonZero: argument.value !== 0 });
        return (0, utils_1.saveByteCode)(context, getTypeAppropriateConstInstruction(argument));
    }
};
exports["default"] = push;
