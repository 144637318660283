"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateLookups = exports.drawCharacterMatrix = exports.drawCharacter = void 0;
var types_1 = require("./types");
var offsetX = 0;
var offsetY = 0;
var asciiCodes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
    61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
    90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
    115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
];
var asciiChars = [
    '\u0000',
    '\u0001',
    '\u0002',
    '\u0003',
    '\u0004',
    '\u0005',
    '\u0006',
    '\u0007',
    '\b',
    '\t',
    '\n',
    '\u000b',
    '\f',
    '\r',
    '\u000e',
    '\u000f',
    '\u0010',
    '\u0011',
    '\u0012',
    '\u0013',
    '\u0014',
    '\u0015',
    '\u0016',
    '\u0017',
    '\u0018',
    '\u0019',
    '\u001a',
    '\u001b',
    '\u001c',
    '\u001d',
    '\u001e',
    '\u001f',
    ' ',
    '!',
    '"',
    '#',
    '$',
    '%',
    '&',
    "'",
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '@',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '[',
    '\\',
    ']',
    '^',
    '_',
    '`',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    '{',
    '|',
    '}',
    '~',
    '',
];
var colorNames = [
    'lineNumber',
    'instruction',
    'codeComment',
    'code',
    'numbers',
    'menuItemText',
    'menuItemTextHighlighted',
    'dialogText',
    'dialogTitle',
    'binaryZero',
    'binaryOne',
];
function generateFontPositions(characterHeight) {
    return Object.fromEntries(colorNames.map(function (color, i) {
        return [color, offsetY + characterHeight * i];
    }));
}
function forEachBit(byte, characterWidth, callback) {
    for (var i = 0; i < characterWidth; i++) {
        var mask = 1 << (characterWidth - 1 - i);
        callback((byte & mask) !== 0, i);
    }
}
function drawCharacter(font, charCode, characterWidth, characterHeight) {
    var commands = [];
    var char = typeof charCode === 'string' ? charCode.charCodeAt(0) : charCode;
    var _loop_1 = function (i) {
        forEachBit(font[char * characterHeight + i], characterWidth, function (bit, nthBit) {
            bit && commands.push([types_1.Command.PIXEL, nthBit, i]);
        });
    };
    for (var i = 0; i < characterHeight; i++) {
        _loop_1(i);
    }
    return commands;
}
exports.drawCharacter = drawCharacter;
function drawCharacterMatrix(font, characterWidth, characterHeight, characterMatrix) {
    var commands = [[types_1.Command.SAVE]];
    characterMatrix.forEach(function (characterArray) {
        characterArray.forEach(function (char) {
            commands.push.apply(commands, __spreadArray(__spreadArray([], __read(drawCharacter(font, char, characterWidth, characterHeight)), false), [[
                    types_1.Command.TRANSLATE,
                    characterWidth,
                    0,
                ]], false));
        });
        commands.push([types_1.Command.TRANSLATE, characterArray.length * -8, characterHeight]);
    });
    commands.push([types_1.Command.RESTORE]);
    return commands;
}
exports.drawCharacterMatrix = drawCharacterMatrix;
function generateFont(x, y, font, characterWidth, characterHeight) {
    if (x === void 0) { x = 0; }
    if (y === void 0) { y = 0; }
    return __spreadArray(__spreadArray([
        [types_1.Command.TRANSLATE, x, y]
    ], __read(asciiCodes.flatMap(function (code) { return __spreadArray(__spreadArray([], __read(drawCharacter(font, code, characterWidth, characterHeight)), false), [
        [types_1.Command.TRANSLATE, characterWidth, 0],
    ], false); })), false), [
        [types_1.Command.RESET_TRANSFORM],
    ], false);
}
function generateFonts(font, characterWidth, characterHeight, colors) {
    return __spreadArray([
        [types_1.Command.RESET_TRANSFORM]
    ], __read(colorNames.flatMap(function (color) {
        return __spreadArray([
            [types_1.Command.FILL_COLOR, colors[color]]
        ], __read(generateFont(offsetX, generateFontPositions(characterHeight)[color], font, characterWidth, characterHeight)), false);
    })), false);
}
exports["default"] = generateFonts;
function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}
var generateLookups = function (characterWidth, characterHeight) {
    return Object.fromEntries(colorNames.map(function (colorName) {
        return [
            "font" + capitalize(colorName),
            Object.fromEntries(__spreadArray(__spreadArray([], __read(asciiCodes), false), __read(asciiChars), false).map(function (char) {
                var code = typeof char === 'string' ? char.charCodeAt(0) : char;
                return [
                    char,
                    {
                        x: code * characterWidth + offsetX,
                        y: generateFontPositions(characterHeight)[colorName],
                        spriteHeight: characterHeight,
                        spriteWidth: characterWidth
                    },
                ];
            })),
        ];
    }));
};
exports.generateLookups = generateLookups;
