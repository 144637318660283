"use strict";
exports.__esModule = true;
exports.Command = void 0;
var Command;
(function (Command) {
    Command[Command["FILL_COLOR"] = 0] = "FILL_COLOR";
    Command[Command["RECTANGLE"] = 1] = "RECTANGLE";
    Command[Command["TRANSLATE"] = 2] = "TRANSLATE";
    Command[Command["SAVE"] = 3] = "SAVE";
    Command[Command["RESTORE"] = 4] = "RESTORE";
    Command[Command["RESET_TRANSFORM"] = 5] = "RESET_TRANSFORM";
    Command[Command["PIXEL"] = 6] = "PIXEL";
})(Command = exports.Command || (exports.Command = {}));
